import { icons } from '@/assets/icons/icons';
import { fetchSmileToken } from '@/services/complianceApi';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import ComplianceSuccessModal from './SuccessModal';

const hostname = window.location.hostname.split('.')[0];

function SelfieStep({ complianceState, closeComplianceModal }) {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [livenessStatus, setLivenessStatus] = useState('none');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const configureSmileIdentityWebIntegration = (token) => {
    SmileIdentity({
      token,
      product: 'smartselfie',
      callback_url: hostname === 'app' ? `https://webhook-service-production.onrender.com/webhooks/smileId` : `https://webhook-service-qe2g.onrender.com/webhooks/smileId`,
      environment: hostname === 'app' ? 'production' : 'sandbox',
      partner_details: {
        partner_id: import.meta.env.VITE_SMILE_PARTNER_ID,
        name: `Billboxx Technologies Ltd`,
        logo_url: `https://res.cloudinary.com/billboxx-dev/image/upload/v1721892821/favicon_nit6fc.svg`,
        policy_url: `https://www.billboxx.com/terms-of-service/`,
        theme_color: '#92C22C'
      },
      onSuccess: () => {
        setLivenessStatus('success');
        setShowSuccessModal(true);
      },
      onClose: () => {
        setLivenessStatus('none');

      },
      onError: () => {
        setLivenessStatus('failed');
      }
    });
  };

  const handleVerifyClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLivenessStatus('none');

    try {
      const token = await queryClient.fetchQuery({
        queryKey: ['smileToken'],
        queryFn: fetchSmileToken
      });
      configureSmileIdentityWebIntegration(token?.data?.token);
    } catch (error) {
      toast.error('Error starting selfie verification, please try again');
      setLivenessStatus('failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Styles>

        {livenessStatus === 'success' && (
          <>
            <div className="icon-view">
              <ReactSVG src={icons.passedSelfie} />
            </div>

            <div className="passed">
              <ReactSVG src={icons.passedCheck} />
              Submitted!
            </div>

            <p>Selfie verification submitted, please wait for approval</p>
          </>
        )}

        {(livenessStatus === 'cancelled' || livenessStatus === 'failed') && (
          <>
            <div className="icon-view">
              <ReactSVG src={icons.failedSelfie} />
            </div>

            <div className="failed">
              <ReactSVG src={icons.failedCheck} />
              Failed!
            </div>

            <p>
              Selfie verification failed,{' '}
              <button className="retry-btn" onClick={handleVerifyClick}>
                please try again
              </button>
            </p>
          </>
        )}

        {complianceState?.selfieVerification?.status === 'Submitted' && livenessStatus === 'none' && (
          <>
            <div className="icon-view">
              <ReactSVG src={icons.passedSelfie} />
            </div>

            <div className="passed">
              <ReactSVG src={icons.passedCheck} />
              Submitted!
            </div>

            <p>Selfie verification submitted, please wait for approval</p>
          </>
        )}

        {
          complianceState?.selfieVerification?.status === 'Pending' &&
          !complianceState?.selfieVerification?.bool &&
          livenessStatus === 'none' && (
            <>
              <div className="icon-view">
                <ReactSVG src={icons.selfieIcon} />
              </div>

              <button onClick={handleVerifyClick} className="verify-btn">
                {isLoading ? (
                  <Oval
                    color="#fff"
                    secondaryColor="#ddd"
                    height={20}
                    width={20}
                    strokeWidth={2}
                    ariaLabel="loading"
                  />
                ) : (
                  'Start verification'
                )}
              </button>
            </>
          )}
      </Styles>
      <ComplianceSuccessModal showSuccessModal={showSuccessModal} setShowSuccessModal={setShowSuccessModal} closeComplianceModal={closeComplianceModal} />
    </>
  );
}

export default SelfieStep;

const Styles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 50px;

  .icon-view {
    width: 100px;
    height: 100px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .verify-btn {
    background-color: ${({ theme }) => theme.colors?.primary};
    color: ${({ theme }) => theme.colors?.white};
    padding: 10px 20px;
    border-radius: 14px;
    margin-top: 40px;
    width: 200px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    border: none;
    cursor: pointer;
  }

  .failed {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 190px;
    height: 56px;
    background-color: #ffe7e2;
    color: #ff4b26;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    margin-top: 40px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .passed {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 190px;
    height: 56px;
    background-color: #d4fff3;
    color: #00ba88;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    margin-top: 40px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors?.secondary};
    margin-top: 20px;

    .retry-btn {
      background-color: transparent;
      border: none;
      color: ${({ theme }) => theme.colors?.primary};
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
