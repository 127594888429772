import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { fetchCompliance, submitPersonalInfo } from '@/services/complianceApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import Input from '@/components/Input/Input';
import { device } from '@/constants/breakpoints';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

const schema = yup
  .object({
    bvn: yup
      .string()
      .matches(/^\d+$/, 'BVN must contain only numbers')
      .min(11, 'BVN must be at least 11 characters')
      .max(11, 'BVN must be at most 11 characters')
      .required('BVN is required'),
    nin: yup
      .string()
      .matches(/^\d+$/, 'NIN must contain only numbers')
      .min(11, 'NIN must be at least 11 characters')
      .max(11, 'NIN must be at most 11 characters')
      .required('NIN is required')
  })
  .required();

const PersonalInfoStep = ({ onNext, compliance, complianceState }) => {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      bvn: compliance?.bvn?.trim() || '',
      nin: compliance?.nin?.trim() || ''
    }
  });

  useEffect(() => {
    reset({
      bvn: compliance?.bvn?.trim() || '',
      nin: compliance?.nin?.trim() || ''
    });
  }, [compliance?.data?.data, reset]);

  const handleSubmitPersonalInfo = useMutation(submitPersonalInfo, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.every((key) =>
            ['compliance', 'complianceState', 'user', 'dashboard-user'].includes(key)
          )
      });
      onNext();
      toast.success('Personal information submitted successfully');
    },
    onError: (error) => {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message[0]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  });

  const onSubmit = (data) => {
    if (
      complianceState?.personalInfo?.status === 'Approved' ||
      complianceState?.personalInfo?.status === 'Submitted'
    ) {
      onNext();
      return;
    }
    handleSubmitPersonalInfo.mutate(data);
  };

  return (
    <Styles>
      <form>
        <div className="input-view">
          <div className="label-view">
            <label htmlFor="bvn">Bank Verification Number(BVN)</label>
          </div>
          <Input
            type="text"
            minLength={11}
            maxLength={11}
            placeholder="Enter BVN"
            {...register('bvn')}
            error={errors?.bvn?.message}
            disabled={
              complianceState?.personalInfo?.status === 'Approved' ||
              complianceState?.personalInfo?.status === 'Submitted'
            }
          />
          {errors?.bvn && <span className="error-label">{errors?.bvn?.message}</span>}
        </div>

        <div className="input-view">
          <div className="label-view">
            <label htmlFor="nin">National Identification Number(NIN)</label>
          </div>
          <Input
            type="text"
            minLength={11}
            maxLength={11}
            placeholder="Enter NIN"
            {...register('nin')}
            error={errors?.nin?.message}
            disabled={
              complianceState?.personalInfo?.status === 'Approved' ||
              complianceState?.personalInfo?.status === 'Submitted'
            }
          />
          {errors?.nin && <span className="error-label">{errors?.nin?.message}</span>}
        </div>
      </form>

      <div className="actions">
        <button className="next" onClick={handleSubmit(onSubmit)}>
          {handleSubmitPersonalInfo.isLoading ? (
            <Oval
              color="#fff"
              secondaryColor="#ddd"
              height={20}
              width={20}
              strokeWidth={3}
              ariaLabel="loading"
            />
          ) : (
            'Next'
          )}
        </button>
      </div>
    </Styles>
  );
};

export default PersonalInfoStep;

const Styles = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;

  form {
    width: 100%;
    height: 400px;
    padding: 0 1rem;
    padding-bottom: 60px;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 100%;
      padding: 0;
    }

    .input-view {
      width: 100%;
      margin-bottom: 26px;

      .label-view {
        width: 100%;
        display: flex;
        margin-bottom: 8px;

        label {
          font-size: 1rem;
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 8px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors?.white};
    padding: 1rem;
    box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 15px 15px;

    button {
      margin-left: 20px;
      height: 40px;
      width: 120px;
      border-radius: 10px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .next {
      background-color: ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
    }

    .previous {
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.primary};
    }
  }
`;

const LoaderView = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
