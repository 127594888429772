import { device } from '@/constants/breakpoints'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as yup from 'yup';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import BankListDropdown from './BankListDropdown';
import Input from '@/components/Input/Input';
import { set } from 'lodash';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useDebounce from '@/hooks/useDebounce';
import { useMutation } from '@tanstack/react-query';
import { calculateTransferCharge } from '@/services/bankingApi';
import CurrencyyInput from '@/components/CurrencyInput';
import { numberWithCommas } from '@/utils/helpers';
import useWallet from '@/hooks/useWallet';
import { Oval } from 'react-loader-spinner';
import theme from '@/constants/theme';
import BeneficiaryOtpModal from './BeneficiaryOtpModal';
import TextArea from '@/components/TextArea/TextArea';

const TransferToBeneficiaryModal = ({ showModal, setShowModal, selectedAccount, setSelectedAccount }) => {
  const { userWallet } = useWallet();
  const [transferCharges, setTransferCharges] = useState(0);
  const [showPinModal, setShowPinModal] = useState(false);

  const schema = yup
    .object({
      amount: yup.string().required('Amount is required')
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const amount = useWatch({
    control,
    name: 'amount'
  });

  const remark = useWatch({
    control,
    name: 'remark'
  });

  const debouncedAmount = useDebounce(amount, 500);

  const handleFees = useMutation(calculateTransferCharge, {
    onSuccess: (data) => {
      setTransferCharges(data?.data?.total);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  useEffect(() => {
    if (debouncedAmount && debouncedAmount >= 100) {
      handleFees.mutate({
        amount: parseFloat(debouncedAmount),
        category: 'transfer'
      });
    } else {
      setTransferCharges(0);
    }
  }, [debouncedAmount]);

  const onSubmit = () => {
    setShowModal(false);
    setShowPinModal(true);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Transfer to beneficiary</h3>
                  <button type="button"
                    onClick={() => {
                      reset({
                        amount: '',
                        remark: ''
                      });
                      setShowModal(false);
                    }}>
                    <CloseIcon />
                  </button>
                </div>

                <div className="heading-view">
                  <h3>Enter the amount you would like to transfer to this recipient</h3>
                </div>

                <FormView>
                  {/* First name */}
                  <InputView>
                    <label htmlFor="name">Amount</label>
                    <Controller
                      name="amount"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <CurrencyyInput
                          name={name}
                          placeholder="Enter amount"
                          decimalsLimit={2}
                          allowNegativeValue={false}
                          prefix="₦"
                          onValueChange={onChange}
                          value={value}
                          onBlur={onBlur}
                          error={!!errors?.amount?.message}
                          className={`currency-input ${errors?.amount && dirtyFields?.amount
                            ? ' has-error'
                            : dirtyFields?.amount
                              ? 'valid'
                              : ''
                            }`}
                        />
                      )}
                    />

                    <span className="fees">
                      Fees:{' '}
                      {handleFees?.isLoading ? (
                        <Oval color={theme.colors.primary} height={10} width={10} />
                      ) : (
                        `₦${numberWithCommas(transferCharges)}`
                      )}
                    </span>

                    {errors?.amount && (
                      <span className="error-label">{errors?.amount?.message}</span>
                    )}
                    {amount > userWallet?.currentBalance && (
                      <span className="error-label">
                        The amount you entered is greater than your current wallet balance of{' '}
                        <span className="strong">
                          ₦{numberWithCommas(userWallet?.currentBalance || '00.00')}
                        </span>
                      </span>
                    )}
                  </InputView>

                  {/* Remark */}
                  <InputView>
                    <div className="label-view">
                      <label htmlFor="remark">
                        Remark <span>(Optional)</span>
                      </label>
                    </div>
                    <TextArea {...register('remark')} placeholder="Enter remark" />
                  </InputView>

                  {/* ===== CTA ====== */}
                  <div className="action-btn">
                    <button type="button"
                      className="btn btn-outline"
                      onClick={() => {
                        reset({
                          amount: '',
                          remark: ''
                        });
                        setShowModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={amount > userWallet?.currentBalance}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Make transfer
                    </button>
                  </div>
                </FormView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <BeneficiaryOtpModal
        showModal={showPinModal}
        setShowModal={setShowPinModal}
        selectedAccount={selectedAccount}
        amount={amount}
        remark={remark}
        reset={reset}
      />
    </>
  )
}

export default TransferToBeneficiaryModal

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 600px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    min-height: 300px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 30px;

  @media ${device.phone} {
    padding: 20px 16px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.1rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 30px;
  }
`;

const FormView = styled.form`
  width: 100%;
  margin-top: 30px;

  .name {
    width: 100%;
    margin-bottom: 20px;

    span {
      font-size: 0.875rem;
      font-weight: 400;
    }

    p {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 5px;
      color: ${({ theme }) => theme.colors?.primary};
    }
  }

  .action-btn {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 50px;

    button {
      height: 56px;
      border-radius: 10px;
      padding: 12px 32px;
      font-size: 1rem;
      font-weight: 600;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .btn-outline {
      width: 40%;
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.primary};
    }

    .btn-primary {
      width: 60%;
      background-color: ${({ theme }) => theme.colors?.primary};
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 20px;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .error-label {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.error};
    margin-top: 5px;
  }

  .fees {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.primary};
    margin-top: 5px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .input-info-view {
    width: 100%;
    display: flex;
    margin-top: 8px;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }
`;