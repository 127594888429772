import React, { useEffect, useCallback, memo } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { ReactSVG } from 'react-svg';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { icons } from '@/assets/icons/icons';
import { device } from '@/constants/breakpoints';

const modalRoot = document.createElement('div');
modalRoot.setAttribute('id', 'modal-root');
document.body.appendChild(modalRoot);

const overlayVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.2 }
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.2, delay: 0.1 }
  }
};

const modalVariants = {
  hidden: {
    opacity: 0,
    y: -20,
    scale: 0.95
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: 'spring',
      duration: 0.3,
      bounce: 0.25
    }
  },
  exit: {
    opacity: 0,
    y: 20,
    scale: 0.95,
    transition: { duration: 0.2 }
  }
};

const Modal = memo(({ isOpen, onClose, title, children }) => {
  const handleEscape = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, handleEscape]);

  const handleOverlayClick = useCallback(
    (e) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose]
  );

  const modalContent = (
    <AnimatePresence mode="wait">
      {isOpen && (
        <StyledOverlay
          variants={overlayVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          onClick={handleOverlayClick}>
          <StyledModal
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            role="dialog"
            aria-modal="true">
            <ModalHeader>
              <h2>{title}</h2>
              <CloseButton onClick={onClose} aria-label="Close modal">
                <ReactSVG src={icons.closeIcon} />
              </CloseButton>
            </ModalHeader>
            {children}
          </StyledModal>
        </StyledOverlay>
      )}
    </AnimatePresence>
  );

  return createPortal(modalContent, modalRoot);
});

Modal.displayName = 'Modal';

export { Modal };

const StyledOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  overflow: hidden;
`;

const StyledModal = styled(motion.div)`
  background: white;
  border-radius: 15px;
  width: 500px;
  max-width: 90%;
  max-height: 90vh;
  min-height: 50vh;
  position: relative;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

  @media ${device.phone} {
    max-width: 96%;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s;
  z-index: 1;

  &:hover {
    color: #1f2937;
  }
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  h2 {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
