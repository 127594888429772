import { icons } from '@/assets/icons/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

const AnnouncementBanner = ({ notification, showBanner, setShowBanner }) => {

  // Get the link from notification
  const notificationLink = notification?.link || '';

  // Check if it's an external link (starts with http:// or https:// or www.)
  const isExternalLink = /^(https?:\/\/|www\.)/.test(notificationLink)

  const linkElement = notificationLink ? (
    isExternalLink ? (
      <a href={notificationLink} target="_blank" rel="noopener noreferrer">Learn more</a>
    ) : (
      <Link to={notificationLink}>Learn more</Link>
    )
  ) : null;

  const closeBanner = () => {
    localStorage.setItem("closedAnnouncementBanner", JSON.stringify(notification?.id));
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <Styles>
      <button
        className="close-btn"
        onClick={closeBanner}
      >
        <ReactSVG src={icons.closeIcon} />
      </button>
      <p>
        {notification?.message}
        {notification?.link && linkElement}
      </p>
    </Styles>
  );
};

export default AnnouncementBanner;

const Styles = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${({ theme }) => theme.colors.primaryFocus};
  height: auto;
  padding: 20px 20px;
  z-index: 8000;
  display: flex;
  align-items: center;
  justify-content: center;

  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1000;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.secondary};
    margin: 0 10px;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: underline;
    margin-left: 10px;
  }
`;
