import api from './api';

/**
 * * Submits the compliance data
 * @param {*} data - Compliance data
 * @returns {Promise} - Returns the created compliance
 */
export const submitCompliance = async (data) => {
  const response = await api.put(`/profile/compliance/submit`, data);
  return response.data;
};

/**
 * * Fetches the compliance data
 * @returns {Promise} - Returns the compliance data
 */
export const fetchCompliance = async () => {
  const response = await api.get(`/profile/compliance`);
  return response.data;
};

export const fetchComplianceState = async () => {
  const response = await api.get(`/profile/compliance/verification/state`);
  return response.data;
};

export const fetchSmileToken = async () => {
  const response = await api.get(`/profile/compliance/smileId/web/token`);
  return response.data;
};

export const submitPersonalInfo = async (data) => {
  const response = await api.put(`/profile/compliance/v2/submit/personal`, data);
  return response.data;
};

export const submitBusinessInfo = async (data) => {
  const response = await api.put(`/profile/compliance/v2/submit/business`, data);
  return response.data;
};