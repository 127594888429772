/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as CheckedIcon } from '@/assets/icons/circle-checked.icon.svg';
import { ReactComponent as UncheckedIcon } from '@/assets/icons/circle.icon.svg';
import { ReactComponent as LinkIcon } from '@/assets/icons/link.icon.svg';
import { device } from '@/constants/breakpoints';
import withAuthentication from '@/hooks/withAuthentication';

const sampleHeaders = [
  { label: 'Customer name', key: 'customerName' },
  { label: 'Description', key: 'description' },
  { label: 'Amount', key: 'amount' },
  { label: 'Invoice number', key: 'invoiceNumber' },
  { label: 'Due date', key: 'dueDate' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phone' },
  { label: 'Requires Approval (Yes or No)', key: 'requiresApproval' }
];

const sampleInvoice = [
  {
    customerName: 'ABC Ventures',
    description: 'Equipments',
    amount: '5,320.00',
    invoiceNumber: 'INV-123',
    dueDate: '21/03/2023',
    email: 'abc@billboxx.com',
    phone: '+14753848394',
    requiresApproval: 'Yes'
  },
  {
    customerName: 'Giwa Ltd',
    description: 'Finance',
    amount: '1,575.00',
    invoiceNumber: 'INV-124',
    dueDate: '22/03/2023',
    email: 'abc@billboxx.com',
    phone: '+2349012345678',
    requiresApproval: 'Yes'
  },
  {
    customerName: 'John Doe Plc',
    description: 'Procurement',
    amount: '7,500.00',
    invoiceNumber: 'INV-125',
    dueDate: '23/03/2023',
    email: 'abc@billboxx.com',
    phone: '+2349012349478',
    requiresApproval: 'No'
  }
];

function BulkUploadExplainedInvoice() {
  const navigate = useNavigate();

  return (
    <BulkUploadExplainedView>
      <HeaderView>
        <button type="button" onClick={() => navigate(-1)}>
          <BackIcon />
        </button>
      </HeaderView>

      <ContentView>
        <FlexView>
          <div className="col1">
            <h1>Required details for upload</h1>
            <p>Do make sure your invoice contains the columns with checked details</p>

            <div className="table">
              <div className="table-head">
                <span className="company">Customer Name</span>
                <span>Description</span>
                <span>Amount</span>
                <span>Invoice #</span>
                <span>Due date</span>
              </div>
              <div className="table-body">
                {sampleInvoice.map((invoice, index) => (
                  <div className="table-row" key={index}>
                    <span className="company">{invoice.customerName}</span>
                    <span>{invoice.description}</span>
                    <span>{invoice.amount}</span>
                    <span>{invoice.invoiceNumber}</span>
                    <span>{invoice.dueDate}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="download">
              <CSVLink
                data={sampleInvoice}
                headers={sampleHeaders}
                filename="sample-template.csv"
                className="btn"
                target="_blank">
                <LinkIcon /> Click here to download a template
              </CSVLink>
            </div>
          </div>
          <div className="col2">
            <div className="list">
              <div className="item">
                <CheckedIcon /> <span>Customer Name</span>
              </div>
              <div className="item">
                <CheckedIcon /> <span>Amount</span>
              </div>
              <div className="item">
                <CheckedIcon /> <span>Invoice number</span>
              </div>
              <div className="item">
                <CheckedIcon /> <span>Due date</span>
              </div>
              <div className="item">
                <CheckedIcon /> <span>Description</span>
              </div>
              <div className="item email">
                <CheckedIcon />{' '}
                <span>
                  Email <br />
                </span>
              </div>
              <div className="item email">
                <CheckedIcon />{' '}
                <span>
                  Phone <br />
                </span>
              </div>
            </div>

            <div className="continue">
              <button type="button" onClick={() => navigate('/get-paid/new/bulk-upload')}>
                Upload invoice
              </button>
            </div>
          </div>
        </FlexView>
      </ContentView>
    </BulkUploadExplainedView>
  );
}

export default withAuthentication(BulkUploadExplainedInvoice);

const BulkUploadExplainedView = styled.div`
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;

  @media ${device.phone} {
    width: 100%;
    padding: 0 16px;
    padding-top: 30px;
  }
`;

const HeaderView = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const ContentView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const FlexView = styled.div`
  width: 80%;
  display: flex;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }

  .col1 {
    width: 60%;
    padding: 0 40px;

    @media ${device.phone} {
      width: 100%;
      padding: 0;
    }

    h1 {
      font-size: 2rem;
      max-width: 60%;
      font-weight: 600;
      margin-bottom: 20px;
      color: ${({ theme }) => theme.colors?.secondary};

      @media ${device.phone} {
        max-width: 100%;
      }
    }

    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 19px;
    }

    .table {
      width: 100%;
      margin-top: 40px;

      @media ${device.phone} {
        width: 100%;
        display: none;
      }

      .table-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${({ theme }) => theme.colors?.primary};
        padding: 10px;

        span {
          font-size: 0.75rem;
          font-weight: 600;
          line-height: 19px;
          color: ${({ theme }) => theme.colors?.secondary};
          flex: 1;
        }

        .company {
          flex: 2;
        }
      }

      .table-body {
        width: 100%;
        .table-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #d3e6ff;
          padding: 10px;

          &:nth-child(even) {
            background-color: ${(props) => props.theme.colors?.white};
          }

          span {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 19px;
            color: ${({ theme }) => theme.colors?.black};
            flex: 1;
          }

          .company {
            flex: 2;
          }
        }
      }
    }

    .download {
      margin-top: 60px;

      @media ${device.phone} {
        width: 100%;
        margin-bottom: 50px;
      }

      .btn {
        background-color: #d3e6ff;
        border-radius: 10px;
        color: ${({ theme }) => theme.colors?.black};
        padding: 16px 32px;
      }
    }
  }

  .col2 {
    width: 40%;

    @media ${device.phone} {
      width: 100%;
    }

    .list {
      width: 100%;
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        span {
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 19px;
          margin-left: 10px;
          color: ${({ theme }) => theme.colors?.secondary};

          &.optional {
            font-size: 0.75rem;
            margin: 0;
            color: ${({ theme }) => theme.colors?.black};
            font-weight: 400;
          }
        }
      }
    }

    .continue {
      margin-top: 60px;

      @media ${device.phone} {
        margin-bottom: 40px;
      }

      button {
        background-color: ${({ theme }) => theme.colors?.primary};
        border-radius: 16px;
        color: ${({ theme }) => theme.colors?.white};
        height: 63px;
        width: 230px;
        font-size: 1rem;
        font-weight: 600;

        @media ${device.phone} {
          width: 100%;
        }
      }
    }
  }
`;
