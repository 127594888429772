/* eslint-disable import/no-unresolved */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { ReactComponent as UploadIcon } from '@/assets/icons/upload.icon.svg';

function FilePicker2({ file, setFile, disabled }) {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    const baseUrl = `https://api.cloudinary.com/v1_1/${
      import.meta.env.VITE_CLOUDINARY_CLOUD_NAME
    }/upload`;

    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          toast.error(`File is larger than 10MB`);
        }

        if (err.code === 'file-invalid-type') {
          toast.error(`Error: ${err.message}`);
        }
      });
    });

    acceptedFiles.forEach(async (acceptedFile) => {
      const formData = new FormData();
      formData.append('file', acceptedFile);
      formData.append('upload_preset', `${import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET}`);

      try {
        setLoading(true);
        const response = await fetch(baseUrl, {
          method: 'post',
          body: formData
        });

        const data = await response.json();
        setFile(data?.secure_url);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],
      'image/*': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/msword': []
    },
    maxFiles: 1,
    noClick: false,
    noKeyboard: false,
    maxSize: 10485760
  });

  // const removeFile = (img) => {
  //   if (multiple) {
  //     const filtered = file?.filter((n) => n !== img)
  //     if (filtered?.length > 0) {
  //       setFile(filtered)
  //     } else {
  //       setFile(null)
  //     }
  //   } else {
  //     setFile(null)
  //   }
  // }

  return (
    <PickerView file={file} disabled={disabled}>
      <Picker {...getRootProps()} disabled={disabled}>
        <input
          type="file"
          accept="image/*, application/pdf"
          {...getInputProps()}
          disabled={disabled}
        />
        <div className="title">
          {file ? (
            <span className="name">{file?.substring(file?.lastIndexOf('/') + 1) || 'File'}</span>
          ) : (
            <span className="placeholder">Click to upload document(.pdf, .png, .jpg,)</span>
          )}
        </div>
        <div className="icon">
          {loading ? (
            <Oval
              color="#174078"
              secondaryColor="#D9DBE9"
              ariaLabel="loading"
              height={20}
              width={20}
            />
          ) : (
            <UploadIcon />
          )}
        </div>
      </Picker>
    </PickerView>
  );
}

export default FilePicker2;

const PickerView = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  margin-top: 5px;
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Picker = styled.div`
  width: 100%;
  height: 64px;
  border-radius: 16px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors?.layer5 : theme.colors?.inputBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  input {
    opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;

    .placeholder {
      font-size: 0.875rem;
      font-weight: 400;
      color: ${({ theme, disabled }) =>
        disabled ? theme.colors?.info : theme.colors?.placeholder};
    }

    .name {
      font-size: 0.875rem;
      font-weight: 400;
      color: ${({ theme, disabled }) =>
        disabled ? theme.colors?.info : theme.colors?.activeTitle};
    }
  }

  .icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
