import { device } from '@/constants/breakpoints';
import { deleteBeneficiary, fetchBeneficiaries } from '@/services/bankingApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as AddIcon } from '@/assets/icons/add.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import { ReactComponent as MoreIcon } from '@/assets/icons/kebab.icon.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { ReactComponent as EmptyIcon } from '@/assets/icons/empty.icon.svg';
import { Oval } from 'react-loader-spinner';
import Button from '@/components/Button/Button';
import useDebounce from '@/hooks/useDebounce';
import AddBeneficiaryModal from './AddBeneficiaryModal';
import useOnClickOutside from '@/hooks/useClickOutside';
import toast from 'react-hot-toast';
import PromptModal from '@/components/PromptModal';
import Pagination from '@/components/Pagination';
import TransferToBeneficiaryModal from './TransferToBeneficiaryModal';
import { useSearchParams } from 'react-router-dom';
import TableRowsSelect from '@/components/TableRowSelect';

const Beneficiaries = () => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const limit = searchParams.get('limit');
  const currentPage = searchParams.get('page');
  const [search, setSearch] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const debouncedsearch = useDebounce(search, 500);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOthersModal, setShowOthersModal] = useState(false);
  const [showAddBeneficiaryModal, setShowAddBeneficiaryModal] = useState(false);
  const PAGE_LIMIT = 10;

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const beneficiaries = useQuery({
    queryKey: [
      'beneficiaries',
      {
        page: currentPage ? currentPage : 1,
        limit: limit ? limit : 10,
        search: debouncedsearch?.trim()
      }
    ],
    queryFn: fetchBeneficiaries
  });

  const handleRowsPerPageChange = (value) => {
    setSearchParams(`tab=manage-beneficiaries&page=1&limit=${value}`);
  };

  const onPageChange = (page) => {
    setSearchParams(`tab=manage-beneficiaries&page=${page}&limit=${limit ? limit : 10}`);
  };

  const handleDeleteBeneficiary = useMutation({
    mutationFn: ({ id }) => deleteBeneficiary(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['beneficiaries']);
      setShowDeleteModal(false);
      toast.success('Beneficiary successfully deleted');
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message);
    }
  });

  if (beneficiaries.isFetching && !search) {
    return (
      <BeneficiaryStyles>
        <div className="loader-view">
          <div className="loader">
            <Oval
              color="#92C22C"
              secondaryColor="#ddd"
              height={50}
              width={50}
              strokeWidth={4}
              ariaLabel="loading"
            />
          </div>
        </div>
      </BeneficiaryStyles>
    );
  }

  return (
    <>
      <HeaderView>
        <div className="header">
          <h3>All beneficiaries</h3>
          <div className="beneficiary-actions">
            <SearchView>
              <input
                type="search"
                placeholder="Search Beneficiary"
                onChange={(e) => setSearch(e.target.value)}
              />
              <SearchIcon className="search-icon" />
            </SearchView>
            <Button className="add-new" onClick={() => setShowAddBeneficiaryModal(true)}>
              <AddIcon />
              <p>Add new</p>
            </Button>
          </div>
        </div>
      </HeaderView>
      {beneficiaries?.data?.data?.data?.length === 0 ? (
        <EmptyView>
          <EmptyIcon />
          <h3>No beneficiaries found</h3>
          <p>Please add a beneficiary to start sending payments</p>
          <Button className="add-account" onClick={() => setShowAddBeneficiaryModal(true)}>
            <AddIcon />
            <span>Add beneficiary</span>
          </Button>
        </EmptyView>
      ) : (
        <BeneficiaryStyles>
          <TableHeader>
            <div className={`item recipient`}>
              <span>Name </span>
            </div>
            <div className={`item amount`}>
              <span>Bank Name </span>
            </div>
            <div className={`item type`}>
              <span>Account Number </span>
            </div>
            <div className={`item action`}>{/* <span>Date</span> */}</div>
          </TableHeader>
          <TableBody>
            {beneficiaries?.data?.data?.data?.map((item, i) => (
              <TableRow key={item?.id}>
                <div className="item recipient">
                  <span>{item?.accountName}</span>
                </div>
                <div className="item amount">
                  <span>{item?.bankName}</span>
                </div>
                <div className="item type">
                  <span>{item?.accountNumber}</span>
                </div>
                <div className="item action">
                  <button
                    className="btn-more"
                    onClick={() => {
                      setShowMenu(true);
                      setCurrentIndex(i);
                    }}>
                    <MoreIcon />
                  </button>

                  {/* ===== Dropdown Menu ====== */}
                  <AnimatePresence>
                    {showMenu && currentIndex === i ? (
                      <motion.div
                        className="menu-actions"
                        ref={ref}
                        initial={{ y: -20 }}
                        animate={{ y: 0 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}>
                        <button
                          onClick={() => {
                            setSelectedAccount(item);
                            setShowOthersModal(true);
                          }}>
                          Send Money
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedAccount(item);
                            setShowDeleteModal(true);
                          }}>
                          Delete Beneficiary
                        </button>
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                </div>
              </TableRow>
            ))}
          </TableBody>

          <div className="pagination">
            <Pagination
              itemsPerPage={limit ? parseInt(limit) : 10}
              totalItems={beneficiaries?.data?.data?.meta?.totalItems}
              currentPage={beneficiaries?.data?.data?.meta?.previousPage}
              onPageChange={onPageChange}
            />
            <TableRowsSelect onChange={handleRowsPerPageChange} />
          </div>
        </BeneficiaryStyles>
      )}


      <TransferToBeneficiaryModal
        showModal={showOthersModal}
        setShowModal={setShowOthersModal}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
      />

      <AddBeneficiaryModal
        showModal={showAddBeneficiaryModal}
        setShowModal={setShowAddBeneficiaryModal}
      />

      <PromptModal showModal={showDeleteModal}>
        <div className="header">
          <button type="button" onClick={() => setShowDeleteModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon />
          <h3>Delete Beneficiary</h3>
          <p>Do you really want to delete beneficiary?</p>
          <button
            type="button"
            className="delete"
            onClick={() => handleDeleteBeneficiary.mutate({ id: selectedAccount?.id })}>
            {handleDeleteBeneficiary.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </PromptModal>
    </>
  );
};

export default Beneficiaries;

const HeaderView = styled.div`
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media ${device.phone} {
      flex-direction: column;
      align-items: flex-start;
    }

    .beneficiary-actions {
      display: flex;
      justify-content: center;
      align-items: center;

      @media ${device.phone} {
        width: 100%;
        justify-content: space-between;
        margin-top: 20px;
      }

      .add-new {
        width: 50%;
        height: 2.45rem;
        border-radius: 0.45rem;
        padding: 1rem 0;
        gap: 0.45rem;
        color: ${(props) => props.theme.colors?.white};

        p {
          font-weight: 600;
          font-size: 11.612px;
          letter-spacing: 0.287px;
        }
      }
    }
  }

  h3 {
    font-size: 1.46rem;
    color: ${(props) => props.theme.colors?.secondary};
    font-weight: 600;

    @media ${device.phone} {
      font-size: 1rem;
      text-align: start;
    }
  }
`;

const BeneficiaryStyles = styled.div`
  width: 100%;

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .pagination {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const SearchView = styled.div`
  width: 300px;
  height: 40px;
  position: relative;
  margin-right: 10px;

  @media ${device.phone} {
    width: 68%;
    margin-top: 0px;
  }

  input {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors?.layer3};
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 12px;
    padding-left: 38px;

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 0.75rem;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);

    path {
      stroke: ${({ theme }) => theme.colors?.placeholder};
    }
  }
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors?.layer2};

  .item {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &.recipient {
      flex: 1.2;
    }

    &.ref {
      flex: 1.2;
    }

    &.amount {
      flex: 1;
    }

    &.type {
      flex: 0.8;

      @media ${device.phone} {
        display: none;
      }
    }

    &.status {
      flex: 0.8;
    }

    &.date {
      flex: 0.6;

      @media ${device.phone} {
        flex: 0.8;
      }
    }
  }
`;

const TableBody = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const TableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors?.white};

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors?.layer3};
  }

  .action {
    /* flex: 0.2; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .btn {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .view {
      margin-right: 20px;
    }

    .menu-actions {
      position: absolute;
      top: 0px;
      right: 0;
      background-color: ${(props) => props.theme.colors?.white};
      border-radius: 6px;
      box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
      width: 180px;
      z-index: 100;
      overflow: hidden;

      button {
        width: 100%;
        padding: 16px 24px;
        border: none;
        background-color: ${(props) => props.theme.colors?.white};
        font-size: 0.875rem;
        color: ${(props) => props.theme.colors?.secondary};
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
          background-color: ${(props) => props.theme.colors?.layer2};
        }
      }
    }
  }

  .btn {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item {
    flex: 1;
    display: flex;
    align-items: center;

    span {
      font-size: 0.875rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 400;
      text-transform: capitalize;
    }

    &.recipient {
      flex: 1.2;
    }

    &.ref {
      flex: 1.2;
      flex-wrap: wrap;
      word-wrap: break-word;
    }

    &.amount {
      flex: 1;
    }

    &.type {
      flex: 0.8;

      @media ${device.phone} {
        display: none;
      }
    }

    &.status {
      flex: 0.8;

      span {
        text-transform: capitalize;
        font-weight: 500;
      }
    }

    &.successful {
      span {
        color: ${(props) => props.theme.colors?.success};
      }
    }

    &.failed {
      span {
        color: ${(props) => props.theme.colors?.error_200};
      }
    }

    &.pending {
      span {
        color: ${(props) => props.theme.colors?.warning};
      }
    }

    &.date {
      flex: 0.6;

      @media ${device.phone} {
        flex: 0.8;
      }
    }
  }

  .date {
    flex: 0.6;
  }
`;

const EmptyView = styled.div`
  width: 100%;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 200px;
    height: 200px;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
    text-align: center;
    margin-top: 30px;
  }

  p {
    font-size: 1rem;
    color: ${(props) => props.theme.colors?.secondary};
    text-align: center;
    margin-top: 10px;
  }

  .add-account {
    width: 220px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;


    svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }

    span {
      color: ${(props) => props.theme.colors?.white};
    }
  }
`