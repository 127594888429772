import { ReactComponent as BankIcon } from '@/assets/icons/bank.icon.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as CheckedIcon } from '@/assets/icons/radio-checked.icon.svg';
import { ReactComponent as UncheckedIcon } from '@/assets/icons/unchecked-circle.icon.svg';
import CurrencyyInput from '@/components/CurrencyInput';
import { device } from '@/constants/breakpoints';
import theme from '@/constants/theme';
import useWallet from '@/hooks/useWallet';
import { fetchUserBankAccounts } from '@/services/bankAccountApi';
import { numberWithCommas } from '@/utils/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes, { number } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';
import * as yup from 'yup';
import PaymentOtpModal from './PaymentOtpModal';
import useDebounce from '@/hooks/useDebounce';
import toast from 'react-hot-toast';
import { calculateTransferCharge } from '@/services/bankingApi';
import TextArea from '@/components/TextArea/TextArea';

function AccountChoiceModal({ showModal, setShowModal }) {
  const { userWallet } = useWallet();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [transferCharge, setTransferCharge] = useState(0);

  const bankAccounts = useQuery({
    queryKey: ['bankAccounts'],
    queryFn: fetchUserBankAccounts
  });

  const schema = yup
    .object({
      amount: yup.string().required('Amount is required'),
      remark: yup.string().nullable()
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const amount = useWatch({
    control,
    name: 'amount'
  });

  const remark = useWatch({
    control,
    name: 'remark'
  });

  const debouncedAmount = useDebounce(amount, 500);

  const handleFetchFees = useMutation(calculateTransferCharge, {
    onSuccess: (data) => {
      setTransferCharge(data?.data?.total);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  useEffect(() => {
    if (debouncedAmount && debouncedAmount >= 100) {
      handleFetchFees.mutate({
        amount: parseFloat(debouncedAmount),
        category: 'transfer'
      });
    }
  }, [debouncedAmount]);

  const handleMakeTransfer = (data) => {
    setShowModal(false);
    setShowOtpModal(true);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <ContentView>
                  <div className="header">
                    <h3>Transfer funds</h3>
                    <button type="button" onClick={() => setShowModal(false)}>
                      <CloseIcon />
                    </button>
                  </div>

                  {bankAccounts?.isFetching ? (
                    <LoaderView>
                      <Oval color={theme.colors.primary} height={50} width={50} />
                    </LoaderView>
                  ) : (
                    <div className="content">
                      <TitleView>
                        <p>Please select an account to proceed with your transaction.</p>
                      </TitleView>

                      <AccountsView>
                        {bankAccounts?.data?.data?.map(
                          (account) =>
                            !account?.isInternal && (
                              <AccountView
                                key={account.id}
                                onClick={() => setSelectedAccount(account)}
                                selected={selectedAccount?.id === account.id}>
                                <div className="account-info">
                                  <div className="bank-name">
                                    <div className="icon">
                                      <BankIcon />
                                    </div>
                                    <div className="info">
                                      <h3>{account.bankName}</h3>
                                      <p>{account.accountName}</p>
                                      <p>{account.accountNumber}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="checked-icon">
                                  {selectedAccount?.id === account.id ? (
                                    <CheckedIcon />
                                  ) : (
                                    <UncheckedIcon />
                                  )}
                                </div>
                              </AccountView>
                            )
                        )}
                      </AccountsView>

                      {selectedAccount && (
                        <FormView>
                          <div className="input-view">
                            <label htmlFor="name">Amount</label>
                            <Controller
                              name="amount"
                              control={control}
                              defaultValue=""
                              rules={{ required: true }}
                              render={({ field: { onChange, onBlur, value, name } }) => (
                                <CurrencyyInput
                                  name={name}
                                  placeholder="Enter amount"
                                  decimalsLimit={2}
                                  allowNegativeValue={false}
                                  prefix="₦"
                                  onValueChange={onChange}
                                  value={value}
                                  onBlur={onBlur}
                                  error={!!errors?.amount?.message}
                                  className={`currency-input ${errors?.amount && dirtyFields?.amount
                                    ? ' has-error'
                                    : dirtyFields?.amount
                                      ? 'valid'
                                      : ''
                                    }`}
                                />
                              )}
                            />

                            <span className="fees">
                              Fees:{' '}
                              {handleFetchFees?.isLoading ? (
                                <Oval color={theme.colors.primary} height={10} width={10} />
                              ) : (
                                `₦${numberWithCommas(transferCharge)}`
                              )}
                            </span>

                            {errors?.amount && (
                              <span className="error-label">{errors?.amount?.message}</span>
                            )}
                            {amount > userWallet?.currentBalance && (
                              <span className="error-label">
                                The amount you entered is greater than your current wallet balance
                                of{' '}
                                <span className="strong">
                                  ₦{numberWithCommas(userWallet?.currentBalance || '00.00')}
                                </span>
                              </span>
                            )}
                          </div>

                          <div className="input-view">
                            <div className="label-view">
                              <label htmlFor="remark">
                                Remark <span>(Optional)</span>
                              </label>
                            </div>
                            <TextArea {...register('remark')} placeholder="Enter remark" />
                          </div>

                          <div className="action-btn">
                            <button
                              type="button"
                              className="btn btn-outline"
                              onClick={() => setShowModal(false)}>
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={amount > userWallet?.currentBalance}
                              onClick={handleSubmit(handleMakeTransfer)}>
                              Make transfer
                            </button>
                          </div>
                        </FormView>
                      )}
                    </div>
                  )}
                </ContentView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <PaymentOtpModal
        showModal={showOtpModal}
        setShowModal={setShowOtpModal}
        selectedAccount={selectedAccount}
        amount={getValues('amount')}
        remark={remark}
        reset={reset}
        setAccountData={setSelectedAccount}
        setAccountNumber={() => { }}
        setSelectedBank={() => { }}
      />
    </>
  );
}

export default AccountChoiceModal;

AccountChoiceModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 600px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    min-height: 300px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 30px;

  @media ${device.phone} {
    padding: 20px 16px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.1rem;
      color: ${(props) => props.theme.colors?.secondary};
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 30px;
  }
`;

const TitleView = styled.div`
  width: 100%;

  h3 {
    font-size: 1.1rem;
    color: ${(props) => props.theme.colors?.secondary};
    font-weight: 600;
    margin-bottom: 8px;
  }

  p {
    font-size: 1rem;
    color: ${(props) => props.theme.colors?.secondary};
    font-weight: 400;
    margin-bottom: 30px;
  }
`;

const AccountsView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${device.phone} {
    flex-direction: column;
  }
`;

const AccountView = styled.div`
  width: 48%;
  min-height: 100px;
  display: flex;
  padding: 20px 10px;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, selected }) => (selected ? theme.colors.primary : theme.colors.line)};
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;

  @media ${device.phone} {
    width: 100%;
  }

  .checked-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .account-info {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .bank-name {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 10px;

      .icon {
        width: 40px;
        height: 40px;
        border-radius: 6px;
        background-color: ${(props) => props.theme.colors?.primaryFocus};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        svg {
          width: 20px;
          height: 20px;

          path {
            stroke: ${(props) => props.theme.colors?.primary};
          }
        }
      }

      .info {
        width: calc(100% - 60px);
        h3 {
          font-size: 1rem;
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 600;
          margin-bottom: 5px;
          word-break: break-all;
        }

        p {
          font-size: 0.875rem;
          color: ${(props) => props.theme.colors?.secondary};
          font-weight: 400;
          margin-bottom: 5px;
          word-break: break-all;
        }
      }
    }
  }
`;

const FormView = styled.form`
  width: 100%;

  .input-view {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    input {
      width: 100%;
    }

    label {
      font-size: 0.875rem;
      font-weight: 400;

      span {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors?.info};
      }
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.error};
      margin-top: 5px;

      .strong {
        font-weight: 600;
      }
    }

    .fees {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.primary};
      margin-top: 5px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    .input-info-view {
      width: 100%;
      display: flex;
      margin-top: 5px;

      span {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.info};
      }
    }
  }

  .action-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-left: 10px;
      height: 56px;
      border-radius: 10px;
      padding: 12px 32px;
      font-size: 1rem;
      font-weight: 600;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    .btn-outline {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.primary};
    }

    .btn-primary {
      background-color: ${({ theme }) => theme.colors?.primary};
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
    }
  }
`;

const LoaderView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
