export const icons = {
  inflowIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1719306939/inflow.icon_vhpqs9.svg',
  outflowIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1719306939/outflow.icon_cx805c.svg',
  bankIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1719306938/bank2.icon_fxtmcc.svg',
  copyIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1719906470/copy.icon.svg_rwdgsm.svg',
  purchaseIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1719051963/purchase.icon_wowzuo.svg',
  invoiceIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1719051904/invoice.icon_ibb9zw.svg',
  cashIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1719051934/cash.icon_gfss9z.svg',
  eyeIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1719867037/eye.icon_vig1u5.svg',
  moreIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1719867063/more.icon_ofxk7v.svg',
  filterIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1720097597/filter.icon_l53oen.svg',
  sortIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1720097611/sort.icon_zurt8w.svg',
  nextIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1720097624/next.icon_cgfgcw.svg',
  prevIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1720097635/prev.icon_kwwngr.svg',
  backIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1720097645/back.icon_irfby5.svg',
  closeIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1720097660/close.icon_to0hnw.svg',
  profileIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1719217379/user-circle.icon_kdcr2f.svg',
  dropIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1720370698/dropdown.icon_ic6qwv.svg',
  searchIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1720377392/search.icon_wlxkix.svg',
  reloadIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1721125601/reload.icon_jd6err.svg',
  downloadIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1721134399/download.icon.svg_snqrxj.svg',
  fileIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1721125736/file.icon_dwysxf.svg',
  eyeOpenIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1721295296/eye.icon_e2zhve.svg',
  eyeClosedIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1721295295/eye-off.icon_jmmlf1.svg',
  logo: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1718980355/logo1_ny2imw.svg',
  logoutIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1719137876/logout.icon_xkcmp2.svg',
  warnIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1721892984/warn.icon_pi4bvw.svg',
  partialPayIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1721947604/partial-pay.icon_nvpsrq.svg',
  fullPayIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1721947583/full-pay.icon_biw6tf.svg',
  radioCheckedIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1721951388/radio-checked.icon_zhye4i.svg',
  sendIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1721953291/send.icon_hnrvyb.svg',
  successIcon:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1721984657/success.icon_lryksi.svg',
  profileIcon2:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1737993078/profile3_ulgmdt.svg',
  selfieIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1737993078/selfie_hv4l4k.svg',
  docsIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1737993078/docs_wt2j9z.svg',
  checkedDot:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1738001135/checked-dot_hivf9b.svg',
  dotIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1738010537/circle_w2w6by.svg',
  passedSelfie:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1738146242/passed-selfie_vnsvup.svg',
  failedSelfie:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1738146242/failed-selfie_bbi48p.svg',
  passedCheck:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1738146242/passed-check_jt7ky4.svg',
  failedCheck:
    'https://res.cloudinary.com/billboxx-dev/image/upload/v1738146242/failed-check_jqd0g4.svg',
  infoIcon: 'https://res.cloudinary.com/billboxx-dev/image/upload/v1738663469/info_1_qaddni.svg'
};
