import { fetchAccount, fetchMissingBankAccounts, fetchWallet } from '@/services/bankingApi';
import { useQuery } from '@tanstack/react-query';

const useWallet = () => {
  const bankingAccount = useQuery({
    queryKey: ['bankingAccount'],
    queryFn: fetchAccount
  });

  const userWallet = useQuery({
    queryKey: ['userWallet'],
    queryFn: fetchWallet
  });

  const missingAccounts = useQuery({
    queryKey: ['missingAccounts'],
    queryFn: fetchMissingBankAccounts
  });

  return {
    userWallet: userWallet?.data?.data,
    bankingAccount: bankingAccount?.data?.data,
    missingAccounts: missingAccounts?.data?.data
  };
};

export default useWallet;
