import { icons } from '@/assets/icons/icons';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

export default function Step({ step, isLast, activeStep, index }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('step');

  // Convert activeTab to number for comparison
  const currentStepNumber = activeTab ? parseInt(activeTab, 10) : 0;
  const isActiveTab = index === currentStepNumber;

  return (
    <StepWrapper>
      <IndicatorRow>
        {step.status === 'Approved' ? (
          <ReactSVG src={icons.radioCheckedIcon} />
        ) : (
          <ReactSVG src={icons.checkedDot} />
        )}
        {!isLast && (
          <ConnectorLine
            isLast={isLast}
            status={step.status === 'Approved' ? 'Completed' : step.status}
          />
        )}
      </IndicatorRow>
      <StepTitle isActive={step?.value === activeTab}>{step.title}</StepTitle>
      <StepStatus isActive={step?.value === activeTab} status={step.status}>
        {step.status}
      </StepStatus>
    </StepWrapper>
  );
}

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  width: 100%;
`;

const IndicatorRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ConnectorLine = styled.div`
  height: 1px;
  flex: 1;
  background-color: ${({ status, theme }) =>
    status === 'Completed'
      ? theme.colors.primary
      : status === 'Pending'
        ? theme.colors.primary
        : theme.colors.primary};
  margin: 0 4px;
`;

const StepTitle = styled.div`
  font-size: 0.8rem;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.activeTitle : theme.colors.info)};
  font-weight: 500;
  margin-bottom: 0.2rem;
`;

const StepStatus = styled.div`
  font-size: 0.75rem;
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  color: ${({ status, theme }) =>
    status === 'Approved'
      ? theme.colors.primary
      : status === 'Pending'
        ? theme.colors.warning
        : theme.colors.warning};
`;
