/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { device } from '@/constants/breakpoints';
import CurrencyyInput from '@/components/CurrencyInput';
import { useDispatch, useSelector } from 'react-redux';
import { appSelector, setDiscountAmount } from '@/redux/features/app.slice';
import ReviewDetailsModal from './ReviewDetailsModal';

function PercentageDiscountModal({ showModal, setShowModal }) {
  const dispatch = useDispatch();
  const { discountInvoice } = useSelector(appSelector);
  const [showReviewDetails, setShowReviewDetails] = useState(false);

  const maxDiscount = discountInvoice?.balance * 0.85;

  const schema = yup
    .object({
      amount: yup
        .number()
        .required('Amount is required')
        .max(maxDiscount, `Maximum allowed is ${(Math.floor(maxDiscount * 100) / 100).toFixed(2)}`)
        .typeError('You must specify an amount')
    })
    .required();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const amount = useWatch({
    control,
    name: 'amount'
  });

  const handleMaxClick = () => {
    setValue('amount', (Math.floor(maxDiscount * 100) / 100).toFixed(2), { shouldValidate: true });
  };

  const onSubmit = (data) => {
    dispatch(setDiscountAmount(amount));
    setShowModal(false);
    setShowReviewDetails(true);
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Get paid</h3>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>

                <div className="heading-view">
                  <h3>Apply to get paid now, not later</h3>
                </div>

                <FormView>
                  <InputView>
                    <div className="label">
                      <label htmlFor="name">Amount to discount</label>
                    </div>

                    <div className="max-input">
                      <Controller
                        name="amount"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, name } }) => (
                          <CurrencyyInput
                            name={name}
                            placeholder="Enter amount"
                            decimalsLimit={2}
                            allowNegativeValue={false}
                            prefix="₦"
                            onValueChange={onChange}
                            value={amount}
                            onBlur={onBlur}
                            error={!!errors?.amount?.message}
                            className={`currency-input ${
                              errors?.amount && dirtyFields?.amount
                                ? ' has-error'
                                : dirtyFields?.amount
                                  ? 'valid'
                                  : ''
                            }`}
                          />
                        )}
                      />

                      <button type="button" onClick={handleMaxClick}>
                        Max
                      </button>
                    </div>

                    <div className="info">
                      <span>Maximum portion of invoice amount that can be discounted (85%)</span>
                    </div>

                    {errors?.amount && (
                      <span className="error-label">{errors?.amount?.message}</span>
                    )}
                  </InputView>

                  {/* ===== CTA ====== */}
                  <div className="action-btn">
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={() => setShowModal(false)}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSubmit(onSubmit)}>
                      Get paid
                    </button>
                  </div>
                </FormView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <ReviewDetailsModal showModal={showReviewDetails} setShowModal={setShowReviewDetails} />
    </>
  );
}

export default PercentageDiscountModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 14px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .heading-view {
    width: 100%;
    margin-top: 30px;

    h3 {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      line-height: 19px;
    }
  }
`;

const FormView = styled.form`
  width: 100%;
  margin-top: 30px;

  .name {
    width: 100%;
    margin-bottom: 20px;

    span {
      font-size: 0.875rem;
      font-weight: 400;
    }

    p {
      font-size: 1rem;
      font-weight: 600;
      margin-top: 5px;
      color: ${({ theme }) => theme.colors?.primary};
    }
  }

  .action-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;

    button {
      margin-left: 10px;
      height: 56px;
      border-radius: 10px;
      padding: 12px 32px;
      font-size: 1rem;
      font-weight: 600;
    }

    .btn-outline {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.primary};
    }

    .btn-primary {
      background-color: ${({ theme }) => theme.colors?.primary};
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 20px;

  .label {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    label {
      font-size: 0.875rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.black};
    }

    button {
      color: ${({ theme }) => theme.colors?.primary};
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .max-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
    border-radius: 16px;

    button {
      font-size: 0.875rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.primary};
      padding: 0 10px;
      border-left: 1px solid ${({ theme }) => theme.colors?.primary};
    }

    .currency-input {
      width: 100%;
      margin-top: 10px;
      border: none;
      outline: none;
      margin: 0;

      &.has-error {
        border-color: ${({ theme }) => theme.colors?.error};
      }

      &.valid {
        border-color: ${({ theme }) => theme.colors?.success};
      }

      &:focus {
        background-color: transparent;
      }
    }
  }

  .info {
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 5px;

    span {
      font-size: 0.85rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .error-label {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.error};
    margin-top: 5px;
  }

  .input-info-view {
    width: 100%;
    display: flex;
    margin-top: 8px;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }
`;
