/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import parsePhoneNumber from 'libphonenumber-js';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import Input from '@/components/Input/Input';
import PhoneNumberInput from '@/components/PhoneInput';
import { createVendor } from '@/services/vendorApi';
import { device } from '@/constants/breakpoints';
import withAuthentication from '@/hooks/withAuthentication';

const vendorTypes = [
  { id: 1, name: 'Individual', value: 'individual' },
  { id: 2, name: 'Business', value: 'business' }
];

function NewVendor() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [vendorType, setVendorType] = useState(vendorTypes[0]);

  const schema = yup
    .object({
      companyName: (() => {
        let validation = yup.string();
        if (vendorType?.value === 'business') {
          validation = validation.required('Company name is required');
        }
        return validation;
      })(),
      name: yup.string().required('Vendor name is required'),
      phoneNumber: yup
        .string()
        .required("Vendor's phone number is required")
        .min(11, 'Enter a valid phone number')
        .test('validPhoneNumber', 'Please enter a valid phone number', (value) => {
          if (!value) return true; // Let required validation handle empty case
          try {
            const phoneNumber = parsePhoneNumber(value);
            return phoneNumber.isValid();
          } catch {
            return false;
          }
        }),
      email: yup.string().email('Invalid email format').required('Email is required')
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleCreateVendor = useMutation(createVendor, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vendors'] });
      navigate('/make-payments/vendors?page=1');
      toast.success('Vendor created successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = (data) => {
    const formData = {
      ...data,
      companyName: data?.companyName ? data?.companyName : data?.name,
      email: data?.email ? data?.email : null,
      phoneNumber: data?.phoneNumber,
      countryCode: parsePhoneNumber(data?.phoneNumber).countryCallingCode,
      type: vendorType?.value
    };
    handleCreateVendor.mutate(formData);
  };

  return (
    <NewVendorView>
      <BackView>
        <button
          type="button"
          className="back"
          onClick={() => {
            navigate(-1);
          }}>
          <BackIcon />
        </button>
      </BackView>
      <ContainerView>
        <ContentView>
          <InfoView>
            <h3>Add a new vendor</h3>
            {/* <p>
              If you have them in multiples, click <button type="button">here</button> to import the
              .csv file
            </p> */}
          </InfoView>
          <FormView>
            <Form>
              <SelectView>
                <label htmlFor="vendorType">Vendor type</label>
                <div className="options">
                  {vendorTypes.map((item) => (
                    <div className="option" key={item?.id} onClick={() => setVendorType(item)}>
                      <div className="radio-button">
                        {vendorType?.id === item?.id ? <span className="dot"></span> : null}
                      </div>
                      <p>{item?.name}</p>
                    </div>
                  ))}
                </div>
              </SelectView>

              {/* ======= Company name ======= */}
              {vendorType?.value === 'business' && (
                <InputView>
                  <label htmlFor="companyName">Vendor’s Company name</label>
                  <Input
                    type="text"
                    {...register('companyName')}
                    error={!!errors?.companyName?.message}
                    placeholder="Enter company name"
                  />

                  {errors?.companyName && (
                    <span className="error-label">{errors?.companyName?.message}</span>
                  )}
                </InputView>
              )}

              {/* ======= Customer name ======= */}
              <InputView>
                <label htmlFor="name">Vendor’s Fullname</label>
                <Input
                  type="text"
                  {...register('name')}
                  error={errors?.name?.message ? true : false}
                  placeholder="Enter vendor's fullname"
                />

                {errors?.name && <span className="error-label">{errors?.name?.message}</span>}
              </InputView>

              {/* ======= Email address ======= */}
              <InputView>
                <label htmlFor="email">Email address</label>
                <Input
                  type="text"
                  {...register('email')}
                  error={errors?.email?.message ? true : false}
                  placeholder="Enter email address"
                />

                {errors?.email && <span className="error-label">{errors?.email?.message}</span>}
              </InputView>

              {/* Phone number */}
              <InputView>
                <label htmlFor="name">Phone No.</label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <PhoneNumberInput
                      name={name}
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                      error={errors?.phoneNumber?.message ? true : false}
                      className={`currency-input ${errors?.phoneNumber && dirtyFields?.phoneNumber
                        ? ' has-error'
                        : dirtyFields?.phoneNumber
                          ? 'valid'
                          : ''
                        }`}
                    />
                  )}
                />

                {errors?.phoneNumber && (
                  <span className="error-label">{errors?.phoneNumber?.message}</span>
                )}
              </InputView>

              {/* ===== CTA ====== */}
              <ButtonView>
                <button type="button" className="cta-view__save" onClick={handleSubmit(onSubmit)}>
                  {handleCreateVendor.isLoading ? (
                    <Oval
                      color="#FFF"
                      secondaryColor="#ddd"
                      height={30}
                      width={30}
                      strokeWidth={4}
                      ariaLabel="loading"
                    />
                  ) : (
                    'Add vendor'
                  )}
                </button>
              </ButtonView>
            </Form>
          </FormView>
        </ContentView>
      </ContainerView>
    </NewVendorView>
  );
}

export default withAuthentication(NewVendor);

const NewVendorView = styled.div`
  background-color: ${(props) => props.theme.colors?.white};
  height: 100%;
  width: 1300px;
  margin: 0 auto;
  padding-top: 50px;

  @media ${device.phone} {
    width: 100%;
    padding: 30px 16px;
  }
`;

const BackView = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const ContainerView = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const ContentView = styled.div`
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }
`;

const InfoView = styled.div`
  width: 40%;

  @media ${device.phone} {
    width: 100%;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors?.secondary};
    line-height: 38px;
  }

  p {
    font-size: 1rem;
    line-height: 19px;

    button {
      background-color: transparent;
      border: none;
      color: ${({ theme }) => theme.colors?.primary};
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const FormView = styled.div`
  width: 52%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media ${device.phone} {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const SelectView = styled.div`
  width: 100%;
  margin-bottom: 20px;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 14px;

    .option {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 50px;

      .radio-button {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors?.primaryFocus};
        margin-right: 10px;

        .dot {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.primary};
        }
      }

      p {
        font-size: 0.875rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 30px;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .error-label {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.error};
    margin-top: 5px;
  }

  .input-info-view {
    width: 100%;
    display: flex;
    margin-top: 8px;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }
`;

const ButtonView = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  margin-bottom: 50px;

  button {
    width: 200px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 16px;

    @media ${device.phone} {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .cta-view__save {
    background-color: ${({ theme }) => theme.colors?.primary};
    color: ${({ theme }) => theme.colors?.white};
    margin-bottom: 20px;
  }

  .cta-view__draft {
    background-color: ${({ theme }) => theme.colors?.white};
    color: ${({ theme }) => theme.colors?.primary};
    border: 1px solid ${({ theme }) => theme.colors?.primary};
  }
`;
