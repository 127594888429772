// Desc: HOC to check if user is authenticated

import { Navigate } from 'react-router-dom';
import useAuthentication from './useAuthentication';

const hostname = window.location.hostname.split('.')[0];

const withImpersonator = (Component) => (props) => {
  const impersonator =
    hostname === 'app'
      ? import.meta.env.VITE_PROD_IMPERSONATE_EMAIL
      : import.meta.env.VITE_DEV_IMPERSONATE_EMAIL;
  const { authenticated } = useAuthentication();

  if (authenticated && impersonator) {
    return <Component {...props} />;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default withImpersonator;
