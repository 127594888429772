/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.icon.svg';
import { ReactComponent as WhatsAppIcon } from '@/assets/icons/whatsapp.icon.svg';
import Button from '@/components/Button/Button';
import { fetchPublicInvoice, requestApproval } from '@/services/receivablesApi';
import PageLoader from '@/components/PageLoader';
import TagInput from '@/components/TagInput';
import { device } from '@/constants/breakpoints';
import withAuthentication from '@/hooks/withAuthentication';
import WYSIWYG from '@/components/WYSIWYG';
import { numberWithCommas } from '@/utils/helpers';
import FilePicker2 from '@/components/FilePicker2';

const hostname = window.location.origin;
const schema = yup
  .object({
    // customerEmail: yup.string().required('Customer email is required'),
    // note: yup.string().nullable()
  })
  .required();

function RequestApproval() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get('id');
  const [sharableLink, setSharableLink] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerEmails, setCustomerEmails] = useState([]);
  const [note, setNote] = useState('');
  const [fetchingFile, setFetchingFile] = useState(false);
  // const [file, setFile] = useState(null);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sharableLink);
    toast.success('Sharable link copied to clipboard');
  };

  const invoice = useQuery({
    queryKey: ['invoice', invoiceId],
    queryFn: fetchPublicInvoice,
    onSuccess: (data) => {
      setSharableLink(`${hostname}/payment?id=${data?.data?.id}`);
    },
    onError: () => { }
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      customerEmail: invoice?.data?.data?.customer?.email
    }
  });

  useEffect(() => {
    reset({
      customerEmail: invoice?.data?.data?.customer?.email
    });
    setCustomerEmail(invoice?.data?.data?.customer?.email);
    setNote(invoice?.data?.data?.description);
  }, [invoice?.data?.data, reset]);

  const handleRequestApproval = useMutation(requestApproval, {
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      toast.success('Approval request sent successfully');
      navigate('/get-paid/invoices?tab=sent&page=1&limit=10');
    },
    onError: (error) => {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message[0]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  });

  const onSubmitRequestPayment = (data) => {
    const emails = customerEmails?.length > 0 ? customerEmails?.join(',') : customerEmail;
    const formData = {
      billOrInvoiceId: invoiceId,
      customerEmail: emails
    };

    if (emails) {
      handleRequestApproval.mutate(formData);
    } else {
      toast.error('Customer email is required');
    }
  };

  const handleShareToWhatsApp = async () => {
    setFetchingFile(true);

    const invoiceFileData = await queryClient.fetchQuery({
      queryKey: ['invoice-file', invoiceId],
      queryFn: fetchPublicInvoice
    });

    if (!invoice?.data?.data) {
      toast.error('No invoice data found!');
      return;
    }

    const invoiceData = invoice.data.data;
    const message = `https://billboxx.com \n\n Hi ${invoiceData.customer?.companyName || invoiceData.customer?.name
      }, 👋🏾  \n\n ${invoiceData.user?.companies[0]?.name || invoiceData.user?.name
      } is requesting payment for \n\n 🧾 Invoice: ${invoiceData.billOrInvoiceNumber
      }. \n\n 🏷️ Invoice Amount: ₦${numberWithCommas(
        invoiceData.amount || '00.00'
      )}. \n\n 🧾 Amount Paid: ₦${numberWithCommas(
        invoiceData.paidAmount || '0'
      )}. \n\n 💰 Balance Due: ₦${numberWithCommas(
        invoiceData.balance || '00.00'
      )}. \n\n 📁 View invoice: ${invoiceFileData?.data?.fileUrl ? invoiceFileData?.data?.fileUrl : 'N/A'
      }  \n\n 💰 Payment link: ${sharableLink} \n\n Thank you for your patronage 🙏🏾`;

    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;

    window.open(url, '_blank');
    setFetchingFile(false);
  };

  if (invoice.isFetching) {
    return <PageLoader />;
  }

  return (
    <RequestApprovalStyles>
      <div className="back-view">
        <button
          type="button"
          className="back"
          onClick={() => {
            navigate('/get-paid/invoices?tab=draft');
          }}>
          <BackIcon />
        </button>
        <button
          type="button"
          className="back"
          onClick={() => {
            navigate('/get-paid/invoices?tab=draft');
          }}>
          <CloseIcon />
        </button>
      </div>

      <div className="container">
        <div className="row">
          <div className="title-view">
            <h1>Request approval</h1>
            <p>Send your approval request to customer by email or via a shareable link.</p>
          </div>
          <div className="form-view">
            {/* Customer Email */}
            <div className="input-view">
              <div className="label-view">
                <label htmlFor="RequestPayment">Customer email</label>
              </div>
              <TagInput
                input={customerEmail}
                setInput={setCustomerEmail}
                tags={customerEmails}
                setTags={setCustomerEmails}
              />
              <span className="info">
                You can enter multiple valid email addresses by comma-separating them.
              </span>
            </div>

            {/* Note */}
            <div className="input-view">
              <div className="label-view">
                <label htmlFor="RequestPayment">
                  Note <span>(optional)</span>
                </label>
              </div>
              <WYSIWYG value={note} setValue={setNote} placeholder="Enter a note" />
              {errors.note && <span className="error-label">{errors.note.message}</span>}
            </div>

            {/* Document */}
            {/* <div className="input-view">
              <div className="label-view">
                <label htmlFor="reportingDocument">
                  Reporting Document <span>(optional)</span>
                </label>
              </div>

              <FilePicker2 file={file} setFile={setFile} acceptsPDF />
            </div> */}

            {/* Shareable link */}
            <div className="link-view">
              <div className="label-view">
                <label
                  htmlFor="RequestPayment"
                  data-tooltip-id="sharable-tooltip"
                  data-tooltip-content="Copy and share this approval link with your vendor."
                  data-tooltip-place="top">
                  Sharable approval link
                </label>
                <Tooltip id="sharable-tooltip" />
              </div>

              <div className="link-input">
                <span>{sharableLink}</span>{' '}
                <button type="button" onClick={handleShareToWhatsApp}>
                  {
                    fetchingFile ? (<Oval color="#4a4a4a" secondaryColor="#ddd" height={30} width={30} strokeWidth={4} ariaLabel="loading" />) : (<WhatsAppIcon />)
                  }
                </button>
                <button type="button" onClick={copyToClipboard}>
                  <CopyIcon />
                </button>
              </div>
            </div>

            {/* Button view */}
            <div className="button-view">
              <Button
                type="button"
                styleType="primary"
                width="100%"
                onClick={handleSubmit(onSubmitRequestPayment)}>
                {handleRequestApproval.isLoading ? (
                  <Oval
                    color="#FFF"
                    secondaryColor="#ddd"
                    height={30}
                    width={30}
                    strokeWidth={4}
                    ariaLabel="loading"
                  />
                ) : (
                  'Send request'
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </RequestApprovalStyles>
  );
}

export default withAuthentication(RequestApproval);

const RequestApprovalStyles = styled.div`
  width: 1300px;
  margin: 0 auto;
  padding-top: 50px;

  @media ${device.phone} {
    width: 100%;
    padding: 30px 16px;
  }

  .back-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .container {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;

    @media ${device.phone} {
      width: 100%;
      flex-direction: column;
    }

    .row {
      width: 70%;
      display: flex;
      justify-content: center;
      margin-top: 40px;

      @media ${device.phone} {
        width: 100%;
        flex-direction: column;
        margin-top: 0px;
      }

      .title-view {
        width: 40%;
        padding-right: 80px;

        @media ${device.phone} {
          width: 100%;
          margin-bottom: 30px;
        }

        h1 {
          font-size: 2rem;
          font-weight: 600;
          color: ${({ theme }) => theme.colors?.secondary};

          @media ${device.phone} {
            width: 100%;
            font-size: 1.5rem;
          }
        }

        p {
          font-size: 1rem;
          line-height: 19px;
          margin-top: 20px;
        }
      }

      .form-view {
        width: 60%;
        padding-left: 80px;

        @media ${device.phone} {
          width: 100%;
          padding-left: 0px;
        }

        .input-view {
          width: 100%;
          margin-bottom: 30px;

          .label-view {
            width: 100%;
            display: flex;
            margin-bottom: 8px;

            label {
              font-size: 1rem;
            }

            span {
              font-size: 0.875rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }

          .error-label {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.colors?.error};
            margin-top: 5px;
          }

          .input-info-view {
            width: 100%;
            display: flex;
            margin-top: 8px;

            span {
              font-size: 0.875rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }

          .info {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.colors?.info};
            padding-top: 5px;
          }
        }

        .link {
          width: 100%;
          margin-bottom: 30px;
          display: flex;
          align-items: center;

          button {
            display: flex;
            align-items: center;

            span {
              font-size: 1rem;
              color: ${({ theme }) => theme.colors?.secondary};
              margin: 0 10px;
            }

            .arrow {
              transition: all 0.3s ease;
            }

            &:hover {
              cursor: pointer;

              .arrow {
                transform: translateX(5px);
              }
            }
          }
        }

        .link-view {
          width: 100%;
          margin-bottom: 30px;
          margin-top: 0px;

          @media ${device.phone} {
            margin-top: 60px;
          }

          .label-view {
            width: 100%;

            label {
              font-size: 1rem;
            }
          }

          .link-input {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            min-height: 64px;
            background-color: ${({ theme }) => theme.colors?.inputBackground};
            padding: 16px;
            border-radius: 16px;

            span {
              font-size: 0.875rem;
              max-width: 90%;
              word-break: break-all;
            }

            button {
              width: 40px;
              svg {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        .button-view {
          width: 230px;
          margin-top: 50px;

          @media ${device.phone} {
            width: 100%;
          }
        }
      }
    }
  }
`;
