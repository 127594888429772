/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as CheckedIcon } from '@/assets/icons/radio-checked.icon.svg';
import { ReactComponent as FullPayIcon } from '@/assets/icons/full-pay.icon.svg';
import { ReactComponent as PartialPayIcon } from '@/assets/icons/partial-pay.icon.svg';
import CurrencyyInput from '@/components/CurrencyInput';
import { device } from '@/constants/breakpoints';
import Input from '@/components/Input/Input';
import TextArea from '@/components/TextArea/TextArea';
import AccountInfoModal from './AccountInfoModal';
import { initiatePayment } from '@/services/billsApi';

export const paymentTypes = [
  {
    title: 'Full payment',
    description: 'Pay the full amount due.',
    subText: '',
    value: 'full',
    icon: FullPayIcon,
    available: true
  },
  {
    title: 'Partial payment',
    description: 'Pay a part of the amount due.',
    subText: '',
    value: 'partial',
    icon: PartialPayIcon,
    available: true
  }
];

function TransferTypeModal({ showModal, setShowModal, bill, selectedMethod }) {
  const [paymentType, setPaymentType] = useState('full');
  const [showAccountInfoModal, setShowAccountInfoModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [paymentData, setPaymentData] = useState(null);

  const schema = yup
    .object({
      remark: yup.string().nullable(),
      partialAmount: (() => {
        let validation = yup.string();
        if (paymentType === 'partial') {
          validation = validation.required('Amount is required');
        }
        return validation;
      })(),
      email: (() => {
        let validation = yup.string();
        if (!bill?.customer?.email) {
          validation = validation.required('Email address is required');
        }
        return validation;
      })()
    })
    .required();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleInitiateTransferPayment = useMutation(initiatePayment, {
    onSuccess: async (data) => {
      setShowModal(true);
      setShowAccountInfoModal(true);
      setPaymentData(data?.data);
    },
    onError: (error) => {
      if (error?.response?.status === 400) {
        setShowModal(false);
        toast.error(error?.response?.data?.message[0]);
      } else {
        setShowModal(false);
        toast.error(error?.response?.data?.message);
      }
    }
  });

  const handleInitiatePayment = useMutation(initiatePayment, {
    onSuccess: async (data) => {
      window.location.href = data?.data?.authorizationUrl;
    },
    onError: (error) => {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message[0]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  });

  const handleContinue = (data) => {
    // setShowModal(false);
    if (paymentType === 'full') {
      setAmount(bill?.balance);

      if (selectedMethod === 'bank-transfer') {
        const payData = {
          amount: parseFloat(bill?.balance),
          billId: bill?.id,
          name: bill?.customer?.companyName || bill?.customer?.name,
          email: bill?.customer?.email || data?.email,
          channel: 'bank-transfer'
        };
        handleInitiateTransferPayment.mutate(payData);
      }
    } else {
      setAmount(data?.partialAmount);

      if (selectedMethod === 'bank-transfer') {
        const payData = {
          amount: parseFloat(data?.partialAmount, 10),
          billId: bill?.id,
          name: bill?.customer?.companyName || bill?.customer?.name,
          email: bill?.customer?.email || data?.email,
          channel: 'bank-transfer'
        };
        handleInitiateTransferPayment.mutate(payData);
      }
    }
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <h3>Select payment type</h3>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>

                <FormView>
                  <div className="options">
                    {paymentTypes?.map((method) => {
                      const Icon = method?.icon;
                      return (
                        <div
                          className={`option ${paymentType === method?.value ? 'selected' : ''}`}
                          key={method?.value}
                          role="button"
                          tabIndex={0}
                          onClick={() => setPaymentType(method?.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              setPaymentType(method?.value);
                            }
                          }}>
                          {paymentType === method?.value && <CheckedIcon className="checked" />}
                          <div className="icon">
                            <Icon />
                          </div>
                          <div className={`info ${method?.available ? '' : 'fade'}`}>
                            <h3>{method?.title}</h3>
                            <p>{method?.description}</p>
                            <br />
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {paymentType === 'partial' && (
                    <div className="input-view">
                      <label htmlFor="name">Payment amount</label>

                      <Controller
                        name="partialAmount"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, name } }) => (
                          <CurrencyyInput
                            name={name}
                            placeholder="Enter partial amount"
                            decimalsLimit={2}
                            allowNegativeValue={false}
                            prefix="₦"
                            onValueChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            error={errors?.partialAmount?.message}
                            className={`currency-input ${errors?.partialAmount && dirtyFields?.partialAmount
                              ? ' has-error'
                              : dirtyFields?.partialAmount
                                ? 'valid'
                                : ''
                              }`}
                          />
                        )}
                      />

                      {errors?.partialAmount && (
                        <span className="error-label">{errors?.partialAmount?.message}</span>
                      )}
                    </div>
                  )}

                  {!bill?.customer?.email && (
                    <div className="input-view">
                      <label htmlFor="email">Email address</label>
                      <Input
                        type="text"
                        {...register('email')}
                        error={!!errors?.email?.message}
                        placeholder="Enter email address"
                      />

                      {errors?.email && (
                        <span className="error-label">{errors?.email?.message}</span>
                      )}
                    </div>
                  )}

                  <div className="input-view">
                    <label htmlFor="name">
                      Remark <span>(Optional)</span>
                    </label>

                    <TextArea {...register('remark')} placeholder="Enter remark" />

                    {errors?.remark && (
                      <span className="error-label">{errors?.remark?.message}</span>
                    )}
                  </div>

                  <div className="cta">
                    <button type="button" onClick={handleSubmit(handleContinue)}>
                      {handleInitiatePayment.isLoading ||
                        handleInitiateTransferPayment?.isLoading ? (
                        <Oval
                          color="#FFF"
                          secondaryColor="#ddd"
                          height={30}
                          width={30}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      ) : (
                        `Make payment`
                      )}
                    </button>
                  </div>
                </FormView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <AccountInfoModal
        showModal={showAccountInfoModal}
        setShowModal={setShowAccountInfoModal}
        paymentData={paymentData}
      />
    </>
  );
}

export default TransferTypeModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px 10px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const FormView = styled.div`
  width: 100%;

  p {
    font-size: 0.9rem;
    color: ${(props) => props.theme.colors?.activeTitle};
    margin-top: 10px;
  }

  .options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    .option {
      width: 48%;
      height: 150px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 16px;
      padding: 20px 10px;
      cursor: pointer;
      position: relative;
      border: 1px solid transparent;
      transition: all 0.1s ease-in-out;
      margin-bottom: 10px;
      border: 1px solid ${({ theme }) => theme.colors?.layer};

      .checked {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.colors?.layer};
        margin-right: 24px;
        margin-bottom: 10px;
      }

      .info {
        width: calc(100%);

        h3 {
          font-size: 1.125rem;
          font-weight: 600;
          color: ${({ theme }) => theme.colors?.secondary};
          margin-bottom: 8px;
          text-align: center;
        }

        p {
          font-size: 1rem;
          line-height: 19px;
          color: ${({ theme }) => theme.colors?.secondary};
          font-weight: 400;
          margin-bottom: 4px;
          text-align: center;
        }

        span {
          font-size: 0.875rem;
          line-height: 19px;
          color: ${({ theme }) => theme.colors?.secondary};
          font-weight: 300;
        }

        .soon {
          font-size: 0.875rem;
          line-height: 19px;

          color: ${({ theme }) => theme.colors?.secondary};
          font-weight: 300;
          margin-top: 10px;
          background-color: ${({ theme }) => theme.colors?.inputBackground};
          opacity: 1;
          padding: 4px 8px;
          text-align: center;
        }
      }

      .fade {
        opacity: 0.4;
      }
    }

    .selected {
      border: 1px solid ${({ theme }) => theme.colors?.primary};
    }
  }

  .input-view {
    width: 100%;
    margin: 20px 0;

    label {
      font-size: 0.9rem;
      color: ${(props) => props.theme.colors?.activeTitle};
    }

    .error-label {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.error};
      margin-top: 5px;
    }
  }

  .cta {
    width: 100%;
    margin-top: 30px;

    button {
      width: 100%;
      height: 64px;
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};
      border-radius: 16px;
      font-size: 1.125rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
