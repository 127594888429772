import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import secureLocalStorage from 'react-secure-storage';

import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import { useDispatch } from 'react-redux';
import { impersonateUser, logoutUser } from '@/services/authApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import theme from '@/constants/theme';
import { fetchUser } from '@/services/userApi';
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';
import withImpersonator from '@/hooks/withAuthentication';
import useUser from '@/hooks/useUser';
import { resetAppStateOnLogout } from '@/redux/features/app.slice';

const hostname = window.location.hostname.split('.')[0];

const schema = yup.object({
  email: yup.string().email('Invalid email format').required('Email is required')
});

const Impersonate = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { user, isLoading } = useUser();
  const impersonator =
    hostname === 'app'
      ? import.meta.env.VITE_PROD_IMPERSONATE_EMAIL
      : import.meta.env.VITE_DEV_IMPERSONATE_EMAIL;
  const navigate = useNavigate();
  const [show2faModal, setShow2faModal] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogoutUser = useMutation(logoutUser, {
    onSuccess: (data) => {
      secureLocalStorage.clear();
      secureLocalStorage.removeItem('company');
      dispatch(resetAppStateOnLogout());
      navigate('/login', { replace: true });
    },
    onError: (error) => {
      setIsLoggingOut(false);
      toast.error(error?.response?.data?.message);
    }
  });

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: ''
    }
  });

  const handleLoginUser = useMutation(impersonateUser, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
      secureLocalStorage.setItem('ut', data?.data?.accessToken);
      secureLocalStorage.setItem('rt', data?.data?.refreshToken);
      secureLocalStorage.setItem('auth', true);

      const user = await queryClient.fetchQuery({
        queryKey: ['user'],
        queryFn: fetchUser
      });

      if (user) {
        const company = user?.data?.company?.id;
        if (company && user?.data?.hasCompletedOnboarding) {
          secureLocalStorage.setItem('company', company);
          secureLocalStorage.setItem('auth', true);
          navigate('/dashboard', { replace: true });
        } else {
          secureLocalStorage.setItem('auth', true);
          navigate('/onboarding?page=company-name', { replace: true });
        }
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleLogin = useCallback(
    async (data) => {
      setLoginData(data);
      const isValid = await trigger();
      if (isValid) {
        handleLoginUser.mutate(data);
      }
    },
    [dispatch, trigger]
  );

  // Early return for loading state
  if (isLoading) {
    return (
      <StyledView>
        <Oval color={theme?.colors?.primary} width={50} height={50} />
      </StyledView>
    );
  }

  // Handle unauthorized user
  if (user && user.email !== impersonator && !isLoggingOut) {
    setIsLoggingOut(true);
    // handleLogoutUser.mutate({});
    return null;
  }

  // Only show form for authorized impersonator
  if (!user || user.email !== impersonator) {
    return null;
  }

  return (
    <StyledView>
      <FormView onSubmit={handleSubmit(handleLogin)}>
        <TitleView>
          <h3>Hello {impersonator} 👋</h3>
        </TitleView>
        <InputView>
          <label htmlFor="email">Email</label>
          <Input
            type="email"
            id="email"
            placeholder="Enter email"
            {...register('email')}
            error={!!errors?.email?.message}
          />

          {errors?.email && <span className="error-label">{errors?.email?.message}</span>}
        </InputView>

        <ButtonView>
          <Button width="100%" height="64px" type="submit">
            {handleLoginUser.isLoading ? (
              <Oval
                color={theme?.colors?.white}
                secondaryColor={theme.colors?.layer2}
                height={30}
                width={30}
                strokeWidth={4}
                ariaLabel="loading"
              />
            ) : (
              'Login'
            )}
          </Button>
        </ButtonView>
      </FormView>
    </StyledView>
  );
};

export default withImpersonator(Impersonate);

const StyledView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors?.white};
  padding: 4rem 0;
  position: relative;

  @media ${device.phone} {
    width: 100%;
    padding: 0 16px;
    flex-direction: column-reverse;
    padding-bottom: 100px;
  }
`;

const LogoView = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 2rem 0 0 5rem;

  @media ${device.phone} {
    width: 100%;
    justify-content: center;
    margin-top: 50px;
  }
`;

const TitleView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-bottom: 3.4rem;

  @media ${device.phone} {
    width: 100%;
    align-items: center;
  }

  h3 {
    color: ${(props) => props.theme.colors?.secondary};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
  }
`;

const FormView = styled.form`
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.phone} {
    width: 100%;
  }
`;

const InputView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  & > label {
    margin-bottom: 5px;
    font-size: 0.875rem;
  }

  .error-label {
    color: ${(props) => props.theme.colors?.error};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
  }
`;

const ButtonView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  flex-direction: column;
  align-items: center;

  .reset-view {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    a {
      color: ${(props) => props.theme.colors?.primary};
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-left: 0.5rem;
    }
  }
`;
