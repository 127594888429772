import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';
import { FloatingWhatsApp } from '@carlos8a/react-whatsapp-floating-button';
import ErrorBoundary from './components/ErrorBoundary';
import Layout from './components/Layout';
import { useOnlineStatus } from './hooks/useOnlineStatus';
import MakePayment from './pages/Bills/MakePayment';
import NewBill from './pages/Bills/NewBill';
import BulkUpload from './pages/Bills/bulkUpload/BulkUpload';
import BulkUploadExplained from './pages/Bills/bulkUpload/BulkUploadExplained';
import FileExtract from './pages/Bills/components/FileExtract';
import Customer from './pages/Customers/Customer';
import Customers from './pages/Customers/Customers';
import NewCustomer from './pages/Customers/NewCustomer';
import Dashboard from './pages/Dashboard/Dashboard';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Inboxx from './pages/Inboxx/Inboxx';
import Inflows from './pages/Inflows/Inflows';
import NewInvoice from './pages/Invoices/NewInvoice';
import Payment from './pages/Invoices/Payment';
import Receivables from './pages/Invoices/Invoices';
import RequestPayment from './pages/Invoices/RequestPayment';
import BulkUploadInvoice from './pages/Invoices/bulkUpload/BulkUpload';
import BulkUploadExplainedInvoice from './pages/Invoices/bulkUpload/BulkUploadExplained';
import InvoiceExtract from './pages/Invoices/components/FileExtract';
import CreateInvoice from './pages/Invoices/createInvoice/CreateInvoice';
import EditInvoice from './pages/Invoices/createInvoice/EditInvoice';
import Login from './pages/Login/Login';
import Onboarding from './pages/Onboarding/Onboarding';
import Outboxx from './pages/Outboxx/Outboxx';
import Outflows from './pages/Outflows/Outflows';
import { RedirectRoute } from './pages/RedirectRoute';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Settings from './pages/Settings/Settings';
import Signup from './pages/Signup/Signup';
import Support from './pages/Support/Support';
import NewVendor from './pages/Vendors/NewVendor';
import Vendor from './pages/Vendors/Vendor';
import Vendors from './pages/Vendors/Vendors';
import AccountVerification from './pages/VerifyEmail/AccountVerification';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import VerifyPayment from './pages/VerifyPayment';
import VerifySubscription from './pages/VerifySubscription';
import Banking from './pages/banking/Banking';
import Bills from './pages/Bills/Bills';
import RequestApproval from './pages/Invoices/RequestApproval';
import Archives from './pages/Bills/archives/Archives';
import InvoiceArchives from './pages/Invoices/archives/InvoiceArchives';
import RequestPaymentType from './pages/Invoices/RequestPaymentType';
import PurchaseFinancing from './pages/financing/purchaseFinancing/PurchaseFinancing';
import InvoiceDiscounting from './pages/financing/invoiceDiscounting/InvoiceDiscounting';
import Home from './pages/Home';
import Impersonate from './pages/impersonate/Impersonate';

function App2() {
  const isOnline = useOnlineStatus();
  const [wasOffline, setWasOffline] = useState(false);

  useEffect(() => {
    if (isOnline && wasOffline) {
      toast.success('You are back online', {
        position: 'bottom-left'
      });
      setWasOffline(false);
    } else if (!isOnline) {
      toast.error('You are offline, please check your internet connection', {
        position: 'bottom-left'
      });
      setWasOffline(true);
    }
  }, [isOnline, wasOffline]);

  return (
    <>
      <FloatingWhatsApp
        phoneNumber="+2348120793669" // Required
        accountName="Customer support" // Optional
        avatar="https://res.cloudinary.com/billboxx-dev/image/upload/v1728070642/pin_2_fws8ug.svg" // Optional
        initialMessageByServer="Hi there! How can I assist you?" // Optional
        statusMessage="Available" // Optional
        placeholder="Write here..." // Optional
        allowEsc={true} // Optional
        allowClickAway={true}
      />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/email-activation" element={<AccountVerification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password-reset" element={<ResetPassword />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/verify-payment" element={<VerifyPayment />} />
        <Route path="/verify-subscription" element={<VerifySubscription />} />
        <Route path="/impersonate" element={<Impersonate />} />
        <Route index element={<Home />} />
        <Route path="/" exact element={<Layout />}>
          <Route element={<RedirectRoute />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/banking" element={<Banking />} />
          <Route path="/inboxx" element={<Inboxx />} />
          <Route path="/outboxx" element={<Outboxx />} />
          <Route path="/make-payments/vendors" element={<Vendors />} />
          <Route path="/make-payments/bills" element={<Bills />} />
          <Route path="/make-payments/vendors/:id" element={<Vendor />} />
          <Route path="/make-payments/outflows" element={<Outflows />} />
          <Route path="/make-payments/archives" element={<Archives />} />
          <Route path="/get-paid/invoices" element={<Receivables />} />
          <Route path="/get-paid/customers" element={<Customers />} />
          <Route path="/get-paid/customers/:id" element={<Customer />} />
          <Route path="/get-paid/inflows" element={<Inflows />} />
          <Route path="/get-paid/archives" element={<InvoiceArchives />} />
          <Route path="/financing/purchase-financing" element={<PurchaseFinancing />} />
          <Route path="/financing/invoice-discounting" element={<InvoiceDiscounting />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/support" element={<Support />} />
        </Route>
        <Route path="/make-payments/bills/new" element={<NewBill />} />
        <Route path="/make-payments/bills/pay" element={<MakePayment />} />
        <Route path="/get-paid/invoices/new-invoice" element={<NewInvoice />} />
        <Route path="/get-paid/invoices/request-payment" element={<RequestPayment />} />
        <Route
          path="/get-paid/invoices/request-payment-type/:id"
          element={<RequestPaymentType />}
        />
        <Route path="/get-paid/invoices/request-approval" element={<RequestApproval />} />
        <Route path="/get-paid/customers/create" element={<NewCustomer />} />
        <Route path="/make-payment/vendors/create" element={<NewVendor />} />
        <Route path="/make-payments/bills/new-bill/upload-file" element={<FileExtract />} />
        <Route path="/get-paid/invoices/new-invoice/upload-file" element={<InvoiceExtract />} />
        <Route path="/make-payments/new/bulk-upload-explained" element={<BulkUploadExplained />} />
        <Route path="/make-payments/new/bulk-upload" element={<BulkUpload />} />
        <Route
          path="/get-paid/new/bulk-upload-explained"
          element={<BulkUploadExplainedInvoice />}
        />
        <Route path="/get-paid/new/bulk-upload" element={<BulkUploadInvoice />} />
        <Route path="/get-paid/new/create-invoice" element={<CreateInvoice />} />
        <Route path="/get-paid/invoices/:id" element={<EditInvoice />} />
        <Route path="*" element={<ErrorBoundary />} />
      </Routes>
    </>
  );
}

export default App2;
