import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { ReactSVG } from 'react-svg';
import { useQuery } from '@tanstack/react-query';
import { fetchTerms } from '@/services/termsApi';
import { icons } from '@/assets/icons/icons';
import { device } from '@/constants/breakpoints';

function OfferTermsModal({ showModal, setShowModal }) {
  const terms = useQuery({
    queryKey: ['terms'],
    queryFn: fetchTerms
  });

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              {/* ======== Content ======== */}
              <ModalContent>
                <div className="header">
                  <span></span>
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}>
                    <ReactSVG src={icons.closeIcon} />
                  </button>
                </div>

                {/* <p className="info">
                  Terms and conditions of <strong>Purchase Financing</strong>
                </p> */}

                {terms.isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <TermsView>{parse(terms.data?.data?.[3]?.content)}</TermsView>
                )}
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>
    </>
  );
}

export default OfferTermsModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 90000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme?.colors?.white};
    z-index: 30000;
    position: relative;
    border-radius: 4px;
    max-width: 55%;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;

    overflow-y: scroll;

    @media ${device.phone} {
      max-width: 96%;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 20px;

  @media ${device.phone} {
    width: 100%;
    padding: 0px 0px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .info {
    font-size: 1.5rem;
    color: ${(props) => props.theme?.colors?.secondary};
    margin-top: 20px;

    strong {
      color: ${(props) => props.theme?.colors?.secondary};
      font-weight: 600;
    }
  }
`;

const TermsView = styled.div`
  width: 100%;
  padding: 0 30px;
  color: ${(props) => props.theme?.colors?.secondary};

  p {
    strong {
      color: ${(props) => props.theme?.colors?.secondary};
      font-weight: 600;
    }
  }

  ul,
  ol {
    padding-left: 20px;

    li {
      margin-bottom: 10px;
      list-style-type: decimal;
      font-weight: 500;
      line-height: 19px;
    }
  }

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 10px;
      list-style-type: disc;
      font-weight: 500;
      line-height: 19px;
    }
  }

  ol {
    list-style-type: decimal;
  }

  p {
    font-size: 1rem;
    color: ${(props) => props.theme?.colors?.secondary};
    line-height: 19px;
    font-weight: 500;
  }
`;
