import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import SideNavigation from './Sidebar2';
import withAuthentication from '@/hooks/withAuthentication';
import secureLocalStorage from 'react-secure-storage';
import AnnouncementBanner from './AnnouncementBanner';
import { useQuery } from '@tanstack/react-query';
import { fetchSystemNotification } from '@/services/notificationsApi';
import AnnouncementModal from './AnnouncementModal';


function Layout() {
  const company = secureLocalStorage.getItem('company');
  const [openAnnouncementModal, setOpenAnnouncementModal] = useState(false);
  const [showBanner, setShowBanner] = useState(false)

  const { data: systemNotif, isFetching } = useQuery({
    queryKey: ['systemNotif'],
    queryFn: fetchSystemNotification,
    enabled: true,
    onSuccess: (data) => {
      if (data?.data?.length > 0) {
        const closedBannerId = localStorage.getItem("closedAnnouncementBanner");
        const parsedClosedBannerId = closedBannerId ? JSON.parse(closedBannerId) : null;

        const closedModalId = localStorage.getItem("closedAnnouncementModal");
        const parsedClosedModalId = closedModalId ? JSON.parse(closedModalId) : null;

        data.data.forEach((notification) => {
          if (notification.active && notification.type === 'modal') {
            setOpenAnnouncementModal(parsedClosedModalId !== notification.id);
          } else if (notification.active && notification.type === 'banner') {
            setShowBanner(parsedClosedBannerId !== notification.id);
          }
        });
      } else {
        setOpenAnnouncementModal(false);
        setShowBanner(false);
      }
    }
  });

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  if (!company) {
    return <Navigate to="/onboarding?page=company-name" />;
  }

  return (
    <Container>
      {!isFetching && (
        <>
          {systemNotif?.data?.some(notif => notif.active && notif.type === 'banner') && (
            <AnnouncementBanner
              showBanner={showBanner}
              setShowBanner={setShowBanner}
              notification={systemNotif?.data.find(notif => notif.active && notif.type === 'banner')}
            />
          )}
          {systemNotif?.data?.some(notif => notif.active && notif.type === 'modal') && (
            <AnnouncementModal
              notification={systemNotif?.data.find(notif => notif.active && notif.type === 'modal')}
              openAnnouncementModal={openAnnouncementModal}
              setOpenAnnouncementModal={setOpenAnnouncementModal}
            />
          )}
        </>
      )}
      <View $activeBanner={showBanner}>
        <SideNavigation />
        <Outlet />
      </View>
    </Container>
  );
}

export default withAuthentication(Layout);

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: ${({ theme }) => theme.colors?.background};
`;

const View = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: ${({ theme }) => theme.colors?.background};

  ${({ $activeBanner }) => $activeBanner && `
    margin-top: 60px;
  `}

  .nav {
    width: 20%;
  }


`;
