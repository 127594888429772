import { Modal } from '@/components/Modal';
import React from 'react';
import styled from 'styled-components';
import Step from './ComplianceSteps';
import PersonalInfoStep from './PersonalInfoStep';
import BusinessInfoStep from './BusinessInfoStep';
import { fetchCompliance, fetchComplianceState } from '@/services/complianceApi';
import { useQuery } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import SelfieStep from './SelfieStep';
import { useSearchParams } from 'react-router-dom';
import { device } from '@/constants/breakpoints';

export const transformObject = (obj) => {
  const transformed = Object.entries(obj).map(([key, value]) => ({
    title: key
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
    value: key,
    status: value.status
  }));
  const selfieIndex = transformed.findIndex((item) => item.title === 'Selfie Verification');
  if (selfieIndex !== -1) {
    const selfieItem = transformed.splice(selfieIndex, 1)[0];
    transformed.push(selfieItem);
  }

  return transformed;
};

export default function ComplianceModal({
  openComplianceModal,
  setOpenComplianceModal,
  compliance
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get('step');

  const [steps, setSteps] = React.useState([]);

  const [activeStep, setActiveStep] = React.useState({});

  const [activeStepIndex, setActiveStepIndex] = React.useState(0);

  const complianceState = useQuery({
    queryKey: ['complianceState'],
    queryFn: fetchComplianceState,
    enabled: openComplianceModal,
    onSuccess: (data) => {
      const transformedData = transformObject(data?.data);
      setSteps(transformedData);
      setActiveStep(transformedData[activeStepIndex]);
    }
  });

  React.useEffect(() => {
    setActiveStep(steps[activeStepIndex]);
  }, [activeStepIndex]);

  return (
    <Modal
      isOpen={openComplianceModal}
      onClose={() => {
        setSearchParams(`tab=compliance`);
        setOpenComplianceModal(false);
      }}
      title={`Compliance Verification`}>
      {complianceState?.isFetching ? (
        <LoaderView>
          <div className="loader">
            <Oval
              color="#92C22C"
              secondaryColor="#ddd"
              height={50}
              width={50}
              strokeWidth={4}
              ariaLabel="loading"
            />
          </div>
        </LoaderView>
      ) : (
        <ContentView>
          <TabView>
            {steps.map((step, index) => (
              <Step
                key={index}
                step={step}
                isLast={index === steps.length - 1}
                activeStep={activeStep}
                index={index}
              />
            ))}
          </TabView>
          <Content>
            {step === 'personalInfo' && (
              <PersonalInfoStep
                onNext={() => {
                  setSearchParams(`tab=compliance&step=businessDocumentation`);
                }}
                openComplianceModal={openComplianceModal}
                compliance={compliance}
                complianceState={complianceState?.data?.data}
              />
            )}
            {step === 'businessDocumentation' && (
              <BusinessInfoStep
                onPrevious={() => {
                  setSearchParams(`tab=compliance&step=personalInfo`);
                }}
                onNext={() => {
                  setSearchParams(`tab=compliance&step=selfieVerification`);
                }}
                compliance={compliance}
                complianceState={complianceState?.data?.data}
              />
            )}
            {step === 'selfieVerification' && (
              <SelfieStep
                openComplianceModal={openComplianceModal}
                closeComplianceModal={setOpenComplianceModal}
                onPrevious={() => {
                  setSearchParams(`tab=compliance&step=businessDocumentation`);
                }}
                complianceState={complianceState?.data?.data}
              />
            )}
          </Content>
        </ContentView>
      )}
    </Modal>
  );
}

const ContentView = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  @media ${device.phone} {
    padding: 0 1rem;
  }
`;

const TabView = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  padding: 0 1rem;
`;

export const LoaderView = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
