import {
  isValidAmount,
  isValidApproval,
  isValidCompanyName,
  isValidDueDate,
  isValidEmail,
  isValidInvoiceNumber,
  isValidPhone
} from './validators';

export const config = {
  headers: [
    {
      name: 'Customer name',
      inputName: 'customerName',
      required: true,
      headerError: (headerName, rowNumber, columnNumber) => {
        return `${rowNumber} is not correct or missing, ${headerName} entered in column ${columnNumber}. The Header name should be ${rowNumber}`;
      },
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
      validate: (customerName) => {
        return isValidCompanyName(customerName);
      },
      validateError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not valid in row ${rowNumber} / column ${columnNumber}`;
      }
    },
    {
      name: 'Description',
      inputName: 'description',
      required: false,
      headerError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not correct or missing, ${rowNumber} entered in column ${columnNumber}. The Header name should be ${headerName}`;
      },
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      }
    },
    {
      name: 'Amount',
      inputName: 'amount',
      required: true,
      headerError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not correct or missing, ${rowNumber} entered in column ${columnNumber}. The Header name should be ${headerName}`;
      },
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
      validate: (amount) => {
        return isValidAmount(amount);
      },
      validateError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not valid in row ${rowNumber} / column ${columnNumber}, ${headerName} should be a number`;
      }
    },
    {
      name: 'Invoice number',
      inputName: 'invoiceNumber',
      required: true,
      headerError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not correct or missing, ${rowNumber} entered in column ${columnNumber}. The Header name should be ${headerName}`;
      },
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
      validate: (invoiceNumber) => {
        return isValidInvoiceNumber(invoiceNumber);
      },
      validateError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not valid in row ${rowNumber} / column ${columnNumber}`;
      }
    },
    {
      name: 'Due date',
      inputName: 'dueDate',
      required: true,
      headerError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not correct or missing, ${rowNumber} entered in column ${columnNumber}. The Header name should be ${headerName}`;
      },
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
      validate: (dueDate) => {
        return isValidDueDate(dueDate);
      },
      validateError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not valid in row ${rowNumber} / column ${columnNumber}`;
      }
    },
    {
      name: 'Email',
      inputName: 'email',
      required: true,
      headerError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not correct or missing, ${rowNumber} entered in column ${columnNumber}. The Header name should be ${headerName}`;
      },
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
      validate: (email) => {
        return isValidEmail(email);
      },
      validateError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not valid in row ${rowNumber} / column ${columnNumber}`;
      }
    },
    {
      name: 'Phone',
      inputName: 'phone',
      required: true,
      headerError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not correct or missing, ${rowNumber} entered in column ${columnNumber}. The Header name should be ${headerName}`;
      },
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
      validate: (phone) => {
        return isValidPhone(phone);
      },
      validateError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not valid in row ${rowNumber} / column ${columnNumber}`;
      }
    },
    {
      name: 'Requires Approval (Yes or No)',
      inputName: 'requiresApproval',
      required: true,
      headerError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not correct or missing, ${rowNumber} entered in column ${columnNumber}. The Header name should be ${headerName}`;
      },
      requiredError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is required in row ${rowNumber} / column ${columnNumber}`;
      },
      validate: (requiresApproval) => {
        return isValidApproval(requiresApproval);
      },
      validateError: (headerName, rowNumber, columnNumber) => {
        return `${headerName} is not valid in row ${rowNumber} / column ${columnNumber}`;
      }
    }
  ]
};
