/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import Input from '@/components/Input/Input';
import PhoneNumberInput from '@/components/PhoneInput';
import { createCustomer } from '@/services/customerApi';
import { device } from '@/constants/breakpoints';
import { useDispatch, useSelector } from 'react-redux';
import { appSelector, setSearchedUserEmail } from '@/redux/features/app.slice';

const customerTypes = [
  { id: 1, name: 'Individual', value: 'individual' },
  { id: 2, name: 'Business', value: 'business' }
];

function NewCustomerModal({ showModal, setShowModal, setCustomer, setCustomerName }) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [customerType, setCustomerType] = useState(customerTypes[0]);
  const { searchedUserEmail } = useSelector(appSelector);

  const schema = yup
    .object({
      companyName: (() => {
        let validation = yup.string();
        if (customerType?.value === 'business') {
          validation = validation
            .min(3, 'Company name must be at least 3 characters long')
            .required('Company name is required');
        }
        return validation;
      })(),
      name: yup
        .string()
        .min(3, 'Customer name must be at least 3 characters long')
        .required('Customer name is required'),
      phoneNumber: yup
        .string()
        .required("Vendor's phone number is required")
        .min(11, 'Enter a valid phone number')
        .test('validPhoneNumber', 'Please enter a valid phone number', (value) => {
          if (!value) return true; // Let required validation handle empty case
          try {
            const phoneNumber = parsePhoneNumber(value);
            return phoneNumber.isValid();
          } catch {
            return false;
          }
        }),
      email: yup.string().email('Invalid email format').required('Email is required')
    })
    .required();

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyName: '',
      email: searchedUserEmail || '',
      name: '',
      phoneNumber: ''
    }
  });

  useEffect(() => {
    reset({
      companyName: '',
      email: searchedUserEmail || '',
      name: '',
      phoneNumber: ''
    });
  }, [searchedUserEmail]);

  const handleCreateCustomer = useMutation(createCustomer, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['customers'] });
      setShowModal(false);
      setCustomer(data?.data);
      if (setCustomerName) {
        setCustomerName(data?.data?.name || data?.data?.companyName);
      }
      toast.success('Customer created successfully');
      dispatch(setSearchedUserEmail(''));
    },
    onError: (error) => {
      if (error?.response?.data?.statusCode === 302) {
        setShowModal(false);
        setCustomer(error?.response?.data?.data);
        if (setCustomerName) {
          setCustomerName(
            error?.response?.data?.data?.name || error?.response?.data?.data?.companyName
          );
        }
        return;
      }
      dispatch(setSearchedUserEmail(''));
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = (data) => {
    const formData = {
      ...data,
      email: data?.email ? data?.email?.trim() : null,
      type: customerType?.value,
      phoneNumber: data?.phoneNumber,
      countryCode: parsePhoneNumber(data?.phoneNumber).countryCallingCode,
      companyName: data?.companyName ? data?.companyName?.trim() : data?.name?.trim(),
      name: data?.name?.trim()
    };
    handleCreateCustomer.mutate(formData);
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            <ModalContent>
              <div className="header">
                <h3>Add new customer</h3>
                <button type="button" onClick={() => setShowModal(false)}>
                  <CloseIcon />
                </button>
              </div>

              <div className="warn-info">
                <p>
                  The customer you searched for does not exist. You can add a new customer by
                  filling the form below.
                </p>
              </div>

              <FormView>
                <Form>
                  <SelectView>
                    <label htmlFor="customerType">Customer type</label>
                    <div className="options">
                      {customerTypes.map((item, i) => (
                        <div
                          className="option"
                          key={`customer-type-${i}`}
                          onClick={() => setCustomerType(item)}>
                          <div className="radio-button">
                            {customerType?.id === item?.id ? <span className="dot"></span> : null}
                          </div>
                          <p>{item?.name}</p>
                        </div>
                      ))}
                    </div>
                  </SelectView>

                  {/* ======= Company name ======= */}
                  {customerType?.value === 'business' && (
                    <InputView>
                      <label htmlFor="companyName">Customer’s company name</label>
                      <Input
                        type="text"
                        {...register('companyName')}
                        error={!!errors?.companyName?.message}
                        placeholder="Enter company name"
                      />

                      {errors?.companyName && (
                        <span className="error-label">{errors?.companyName?.message}</span>
                      )}
                    </InputView>
                  )}

                  {/* ======= Customer name ======= */}
                  <InputView>
                    <label htmlFor="name">Customer’s full name</label>
                    <Input
                      type="text"
                      {...register('name')}
                      error={!!errors?.name?.message}
                      placeholder="Enter customer name"
                    />

                    {errors?.name && <span className="error-label">{errors?.name?.message}</span>}
                  </InputView>

                  {/* ======= Email address ======= */}
                  <InputView>
                    <label htmlFor="email">Email address</label>
                    <Input
                      type="text"
                      {...register('email')}
                      error={!!errors?.email?.message}
                      placeholder="Enter email address"
                    />

                    {errors?.email && <span className="error-label">{errors?.email?.message}</span>}
                  </InputView>

                  {/* Phone number */}
                  <InputView>
                    <label htmlFor="name">Phone No.</label>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <PhoneNumberInput
                          name={name}
                          onChange={onChange}
                          value={value}
                          onBlur={onBlur}
                          error={!!errors?.phoneNumber?.message}
                          className={`currency-input ${
                            errors?.phoneNumber && dirtyFields?.phoneNumber
                              ? ' has-error'
                              : dirtyFields?.phoneNumber
                                ? 'valid'
                                : ''
                          }`}
                        />
                      )}
                    />

                    {errors?.phoneNumber && (
                      <span className="error-label">{errors?.phoneNumber?.message}</span>
                    )}
                  </InputView>

                  {/* ===== CTA ====== */}
                  <ButtonView>
                    <button
                      type="button"
                      className="cta-view__save"
                      onClick={handleSubmit(onSubmit)}>
                      {handleCreateCustomer.isLoading ? (
                        <Oval
                          color="#FFF"
                          secondaryColor="#ddd"
                          height={30}
                          width={30}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      ) : (
                        'Add customer'
                      )}
                    </button>
                  </ButtonView>
                </Form>
              </FormView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default NewCustomerModal;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20001;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 550px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 10px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100vw - 32px);
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;

  @media ${device.phone} {
    width: 100%;
    padding: 20px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .warn-info {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    background-color: ${({ theme }) => theme.colors?.warning_200};
    padding: 20px;
    border-radius: 8px;

    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors?.secondary};
      line-height: 19px;
    }
  }
`;

const FormView = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 30px;
`;

const Form = styled.form`
  width: 100%;
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 20px;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .error-label {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.error};
    margin-top: 5px;
  }

  .input-info-view {
    width: 100%;
    display: flex;
    margin-top: 8px;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }
`;

const SelectView = styled.div`
  width: 100%;
  margin-bottom: 20px;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 14px;

    .option {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 50px;

      .radio-button {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors?.primaryFocus};
        margin-right: 10px;

        .dot {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.primary};
        }
      }

      p {
        font-size: 0.875rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }
`;

const ButtonView = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  flex-direction: column;

  button {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 16px;
  }

  .cta-view__save {
    background-color: ${({ theme }) => theme.colors?.primary};
    color: ${({ theme }) => theme.colors?.white};
    margin-bottom: 20px;
  }

  .cta-view__draft {
    background-color: ${({ theme }) => theme.colors?.white};
    color: ${({ theme }) => theme.colors?.primary};
    border: 1px solid ${({ theme }) => theme.colors?.primary};
  }
`;
