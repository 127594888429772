/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { device } from '@/constants/breakpoints';
import useWallet from '@/hooks/useWallet';
import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import secureLocalStorage from 'react-secure-storage';
import BankAccount from './components/BankAccount';
import AddFunds from './components/AddFunds';
import TransferFunds from './components/TransferFunds';
import ProfileDropdown from '@/components/ProfileDropdown';
import MobileSidebar from '@/components/MobileSidebar';
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.icon.svg';
import { ReactComponent as EmptyIcon } from '@/assets/icons/empty.icon.svg';
import BankAccountMetrics from '../Dashboard/components/BankAccountMetrics';
import { useQuery } from '@tanstack/react-query';
import { fetchCompany, fetchUser } from '@/services/userApi';
import FetchLoader from '@/components/FetchLoader';
import BVNWarnModal from '@/components/BVNWarnModal';
import Beneficiaries from './components/Beneficiaries';
import TransactionHistory from './components/transactionsHistory/TransactionHistory';

const tabs = [
  {
    name: 'Manage Bank Accounts',
    path: 'manage-accounts',
    value: 'manage-accounts'
  },
  {
    name: 'Manage Beneficiaries',
    path: 'manage-beneficiaries',
    value: 'manage-beneficiaries'
  },
  {
    name: 'Add Funds',
    path: 'add-funds',
    value: 'add-funds'
  },
  {
    name: 'Transfer Funds',
    path: 'transfer-funds&page=1&limit=10',
    value: 'transfer-funds'
  },
  {
    name: 'Transaction History',
    path: 'transaction-history&view=all&page=1&limit=10',
    value: 'transaction-history'
  }
];

function Banking() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { bankingAccount } = useWallet();
  const companyId = secureLocalStorage.getItem('company');
  const [openNav, setOpenNav] = useState(false);
  const [showBVNWarning, setShowBVNWarning] = useState(false);

  const user = useQuery({
    queryKey: ['dashboard-user'],
    queryFn: fetchUser,
    onSuccess: (data) => {
      if (data?.data?.complianceVerified && !data?.data?.hasComplianceBvn) {
        setShowBVNWarning(true);
      } else {
        setShowBVNWarning(false);
      }
    }
  });

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany
  });

  if (user?.isFetching) {
    return <FetchLoader />;
  }

  return (
    <>
      <MobileSidebar openNav={openNav} setOpenNav={setOpenNav} />
      <BankingStyles>
        <div className="banking-header">
          <div className="title-view">
            <button type="button" className="menu" onClick={() => setOpenNav(true)}>
              <MenuIcon />
            </button>
            <h3>{company?.data?.data?.name}</h3>
          </div>
          <ProfileDropdown />
        </div>
        <ContentView>
          <BankAccountMetrics />
          {
            bankingAccount && <TabsView>
              {tabs.map((tabItem) => (
                <div key={tabItem.path} className="tab-item">
                  <button
                    type="button"
                    className={`tab-btn ${tab === tabItem.value ? 'active' : ''}`}
                    onClick={() => setSearchParams(`tab=${tabItem?.path}`)}>
                    {tabItem.name}
                  </button>
                </div>
              ))}
            </TabsView>
          }
          {
            bankingAccount ? <InfoView>
              {tab === 'manage-accounts' && <BankAccount />}
              {tab === 'manage-beneficiaries' && <Beneficiaries />}
              {tab === 'add-funds' && <AddFunds />}
              {tab === 'transfer-funds' && <TransferFunds />}
              {tab === 'transaction-history' && <TransactionHistory />}
            </InfoView> : <EmptyView>
              <EmptyIcon />
              <p>Complete your compliance details to enable banking.</p>
              <div className="activate-link">
                <Link to="/settings?tab=compliance">Activate now</Link>
              </div>
            </EmptyView>
          }
        </ContentView>
      </BankingStyles>

      <BVNWarnModal showModal={showBVNWarning} setShowModal={setShowBVNWarning} />
    </>
  );
}

export default Banking;

export const BankingStyles = styled.div`
  width: 80%;
  height: 100%;
  padding: 0 40px 0 0;
  overflow-y: scroll;

  @media ${device.phone} {
    width: 100%;
    padding: 0;
  }

  .banking-header {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.phone} {
      padding: 20px 16px;
    }

    .title-view {
      .menu {
        display: none;
      }
      @media ${device.phone} {
        display: flex;
        align-items: center;

        .menu {
          display: block;
          margin-right: 10px;
        }
      }

      h3 {
        font-size: 1.3rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          font-size: 1rem;
          display: none;
        }
      }
    }
  }
`;

export const ContentView = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  margin-bottom: 30px;
  border-radius: 30px;
  padding: 30px 40px;

  @media ${device.phone} {
    border-radius: 0;
    padding: 30px 16px;
    min-height: 100vh;
  }
`;

export const TabsView = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  overflow-x: scroll;

  @media ${device.phone} {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    border: none;
  }

  .tab-item {
    margin-right: 10px;
    display: flex;

    @media ${device.phone} {
      margin-bottom: 0;
      margin-right: 10px;
    }

    button {
      width: fit-content;
      height: 44px;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
      transition: all 0.3s ease-in-out;
      padding: 12px 32px;
      border-radius: 8px;
      word-break: normal;
      white-space: nowrap;

      @media ${device.phone} {
        width: 200px;
        justify-content: center;
      }

      &:hover {
        color: ${({ theme }) => theme.colors?.secondary};
        background-color: ${({ theme }) => theme.colors?.primaryFocus};
      }
    }

    .active {
      color: ${({ theme }) => theme.colors?.secondary};
      background-color: ${({ theme }) => theme.colors?.primaryFocus};
    }
  }
`;

export const InfoView = styled.div`
  width: 100%;
  height: 100%;

  @media ${device.phone} {
    width: 100%;
  }
`;

const EmptyView = styled.div`
  width: 100%;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 200px;
    height: 200px;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
    text-align: center;
    margin-top: 30px;
  }

  p {
    font-size: 1rem;
    color: ${(props) => props.theme.colors?.secondary};
    text-align: center;
    margin-top: 10px;
  }

  .activate-link {
    margin-top: 20px;
    font-size: 0.875rem;
    font-weight: 500;

    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .add-account {
    width: 220px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;


    svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }

    span {
      color: ${(props) => props.theme.colors?.white};
    }
  }
`
