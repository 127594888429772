/* eslint-disable react/prop-types */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import parsePhoneNumber from 'libphonenumber-js';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import Input from '@/components/Input/Input';
import { updateVendor } from '@/services/vendorApi';
import PhoneNumberInput from '@/components/PhoneInput';
import { device } from '@/constants/breakpoints';

const schema = yup
  .object({
    companyName: yup.string().required('Company name is required'),
    name: yup.string().required('Vendor name is required'),
    phoneNumber: yup
      .string()
      .required("Vendor's phone number is required")
      .min(11, 'Enter a valid phone number')
      .test('validPhoneNumber', 'Please enter a valid phone number', (value) => {
        if (!value) return true; // Let required validation handle empty case
        try {
          const phoneNumber = parsePhoneNumber(value);
          return phoneNumber.isValid();
        } catch {
          return false;
        }
      }),
    email: yup.string().email('Invalid email format').nullable()
  })
  .required();

function EditVendor({ showModal, setShowModal, selectedVendor }) {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyName: selectedVendor?.companyName,
      name: selectedVendor?.name,
      phoneNumber: selectedVendor?.phoneNumber || '',
      email: selectedVendor?.email
    }
  });

  useEffect(() => {
    reset({
      companyName: selectedVendor?.companyName,
      name: selectedVendor?.name,
      phoneNumber: selectedVendor?.phoneNumber || '',
      email: selectedVendor?.email
    });
  }, [selectedVendor, reset]);

  const handleUpdateVendor = useMutation(({ id, data }) => updateVendor(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vendor'] });
      setShowModal(false);
      toast.success('Vendor updated successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = (data) => {
    const formData = {
      ...data,
      phoneNumber: data?.phoneNumber,
      countryCode: parsePhoneNumber(data?.phoneNumber).countryCallingCode
    };

    handleUpdateVendor.mutate({ id: selectedVendor?.id, data: formData });
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======= Content here ====== */}
            <div className="header-view">
              <button type="button" className="back" onClick={() => setShowModal(false)}>
                <BackIcon />
              </button>
            </div>

            <FormView>
              <Form>
                <InputView>
                  <label htmlFor="companyName">Vendor’s company name</label>
                  <Input
                    type="text"
                    {...register('companyName')}
                    error={errors?.companyName?.message ? true : false}
                    placeholder="Enter company name"
                  />

                  {errors?.companyName && (
                    <span className="error-label">{errors?.companyName?.message}</span>
                  )}
                </InputView>

                {/* ======= Customer name ======= */}
                <InputView>
                  <label htmlFor="name">Vendor’s full name</label>
                  <Input
                    type="text"
                    {...register('name')}
                    error={errors?.name?.message ? true : false}
                    placeholder="Enter customer name"
                  />

                  {errors?.name && <span className="error-label">{errors?.name?.message}</span>}
                </InputView>

                {/* ======= Email address ======= */}
                <InputView>
                  <label htmlFor="email">Email address</label>
                  <Input
                    type="text"
                    {...register('email')}
                    error={errors?.email?.message ? true : false}
                    placeholder="Enter email address"
                  />

                  {errors?.email && <span className="error-label">{errors?.email?.message}</span>}
                </InputView>

                {/* Phone number */}
                <InputView>
                  <label htmlFor="name">Phone No.</label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <PhoneNumberInput
                        name={name}
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        error={errors?.phoneNumber?.message ? true : false}
                        className={`currency-input ${errors?.phoneNumber && dirtyFields?.phoneNumber
                            ? ' has-error'
                            : dirtyFields?.phoneNumber
                              ? 'valid'
                              : ''
                          }`}
                      />
                    )}
                  />

                  {errors?.phoneNumber && (
                    <span className="error-label">{errors?.phoneNumber?.message}</span>
                  )}
                </InputView>

                {/* ===== CTA ====== */}
                <ButtonView>
                  <button type="button" className="cta-view__save" onClick={handleSubmit(onSubmit)}>
                    {handleUpdateVendor.isLoading ? (
                      <Oval
                        color="#FFF"
                        secondaryColor="#ddd"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    ) : (
                      'Update vendor'
                    )}
                  </button>
                </ButtonView>
              </Form>
            </FormView>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default EditVendor;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 30px 16px;
    }
  }
`;

const FormView = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const Form = styled.form`
  width: 40%;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
  }
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 30px;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .error-label {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.error};
    margin-top: 5px;
  }

  .input-info-view {
    width: 100%;
    display: flex;
    margin-top: 8px;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }
`;

const ButtonView = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  margin-bottom: 50px;

  button {
    width: 200px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 16px;

    @media ${device.phone} {
      width: 100%;
    }
  }

  .cta-view__save {
    background-color: ${({ theme }) => theme.colors?.primary};
    color: ${({ theme }) => theme.colors?.white};
    margin-bottom: 20px;
  }

  .cta-view__draft {
    background-color: ${({ theme }) => theme.colors?.white};
    color: ${({ theme }) => theme.colors?.primary};
    border: 1px solid ${({ theme }) => theme.colors?.primary};
  }
`;
