import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.icon.svg';
import PDFViewer from '@/components/PDFViewer';
import TextArea from '@/components/TextArea/TextArea';
import Input from '@/components/Input/Input';
import CurrencyyInput from '@/components/CurrencyInput';
import DropArea from './DropArea';
import SearchDropdown2 from '@/components/SearchDropdown2';
import { createCustomer, deleteCustomer, fetchCustomers } from '@/services/customerApi';
import { createInvoice } from '@/services/receivablesApi';
import { fetchUser } from '@/services/userApi';
import NewCustomerModal from './NewCustomerForm';
import { device } from '@/constants/breakpoints';
import SearchDropdown from '@/components/SearchDropdown';
import withAuthentication from '@/hooks/withAuthentication';
import FindCustomerModal from '../invoiceFinancing/FindCustomer';
import PhoneNumberInput from '@/components/PhoneInput';

const billFrequency = [
  { id: 1, name: 'One time', value: 'one-time' },
  { id: 1, name: 'Daily', value: 'daily' },
  { id: 2, name: 'Weekly', value: 'weekly' },
  { id: 3, name: 'Monthly', value: 'monthly' },
  { id: 3, name: 'Quarterly', value: 'quaterly' },
  { id: 3, name: 'Yearly', value: 'yearly' }
];

function InvoiceExtract() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [, setShowUploadButton] = useState(true);
  const [file, setFile] = useState(null);
  const [extractedFile, setExtractedFile] = useState(null);
  const [customerName, setCustomerName] = useState('');
  const [customer, setCustomer] = useState(null);
  const [, setExtractedCustomerName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [frequency, setFrequency] = useState(billFrequency[0]);
  const [statusType, setStatusType] = useState('draft');
  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [showFindCustomerModal, setShowFindCustomerModal] = useState(false);

  const [expires, setExpires] = useState(false);

  const schema = yup
    .object({
      recurringStartDate: (() => {
        let validation = yup.string();
        if (frequency?.value !== 'one-time') {
          validation = validation.required('Start date is required');
        }
        return validation;
      })(),
      recurringEndDate: (() => {
        let validation = yup.string();
        if (!expires && frequency?.value !== 'one-time') {
          validation = validation.required('End date is required');
        }
        return validation;
      })(),
      amount: yup.string().required('Invoice amount is required'),
      dueDate: yup.string().required('Due date is required'),
      description: yup.string().nullable(),
      billOrInvoiceNumber: yup.string().required('Invoice number is required'),
      requiresApproval: yup.boolean().required('Approval status is required'),
      email: (() => {
        let validation = yup.string();
        if (!customer?.email) {
          validation = validation.email('Invalid email').required('Email is required');
        }
        return validation;
      })(),
      phoneNumber: yup
        .string()
        .required("Customers's phone number is required")
        .min(11, 'Enter a valid phone number')
        .test('validPhoneNumber', 'Please enter a valid phone number', (value) => {
          if (!value) return true; // Let required validation handle empty case
          try {
            const phoneNumber = parsePhoneNumber(value);
            return phoneNumber.isValid();
          } catch {
            return false;
          }
        })
    })
    .required();

  const customers = useQuery({
    queryKey: ['customers', { page: 1, pageLimit: 1000 }],
    queryFn: fetchCustomers
  });

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  const {
    register,
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields, isValid }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: extractedFile?.totalAmount,
      billOrInvoiceNumber: extractedFile?.billOrInvoiceNumber,
      dueDate: extractedFile?.dueDate ? extractedFile?.dueDate : null,
      description: extractedFile?.description,
      email: customer?.email || customerEmail || '',
      phoneNumber: customer?.phoneNumber || '',
    }
  });

  const requiresApproval = useWatch({
    control,
    name: 'requiresApproval'
  });

  useEffect(() => {
    reset({
      amount: extractedFile?.totalAmount,
      billOrInvoiceNumber: extractedFile?.billOrInvoiceNumber,
      dueDate: extractedFile?.dueDate ? extractedFile?.dueDate : null,
      description: extractedFile?.description,
      email: customer?.email || customerEmail || '',
      phoneNumber: customer?.phoneNumber || '',
    });
  }, [extractedFile, customer, customerEmail, reset]);

  const handleDeleteCustomer = useMutation(deleteCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customers'] });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleSaveInvoice = useMutation(createInvoice, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      navigate('/get-paid/invoices?tab=draft');
      toast.success('Invoice created successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      if (customerId) {
        handleDeleteCustomer.mutate(customerId);
      }
    }
  });

  const handleSendInvoice = useMutation(createInvoice, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      if (user?.data?.data?.hasBankAccount) {
        if (data?.data?.requiresApproval) {
          navigate(`/get-paid/invoices/request-approval?id=${data?.data?.id}`);
        } else {
          navigate(`/get-paid/invoices/request-payment?id=${data?.data?.id}`);
        }
        toast.success('Invoice created successfully');
      } else {
        navigate('/settings?tab=bank-account');
        toast.success('Invoice created successfully, add bank account to start receiving payments');
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      if (customerId) {
        handleDeleteCustomer.mutate(customerId);
      }
    }
  });

  const handleCreateCustomer = useMutation(createCustomer, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['customers'] });
      setCustomerId(data?.data?.id);

      const invoiceData = {
        customerId: data?.data?.id,
        email: getValues('email'),
        amount: `${getValues('amount')}`,
        frequency: frequency?.value,
        status: 'draft',
        billOrInvoiceNumber: getValues('billOrInvoiceNumber'),
        requiresApproval: getValues('requiresApproval') === 'true' ? true : false,
        description: getValues('description'),
        dueDate: getValues('dueDate') ? new Date(getValues('dueDate')).toISOString() : undefined,
        recurringStartDate: getValues('recurringStartDate')
          ? new Date(getValues('recurringStartDate')).toISOString()
          : undefined,
        recurringEndDate: getValues('recurringEndDate')
          ? new Date(getValues('recurringEndDate')).toISOString()
          : undefined,
        shouldExpireRecurring: expires ? 'no' : 'yes',
        fileUrl: file
      };

      const filteredInvoiceData = Object.fromEntries(
        Object.entries(invoiceData).filter(([_, v]) => v != null && v !== '')
      );

      if (!isValid) {
        return;
      } else {
        if (statusType === 'sent') {
          handleSendInvoice.mutate(filteredInvoiceData);
        } else {
          handleSaveInvoice.mutate(filteredInvoiceData);
        }
      }
    },
    onError: (error) => {
      if (error?.response?.data?.statusCode === 302) {
        setCustomerId(error?.response?.data?.data?.id);

        const invoiceData = {
          customerId: error?.response?.data?.data?.id,
          amount: `${getValues('amount')}`,
          email: getValues('email'),
          frequency: frequency?.value,
          status: 'draft',
          billOrInvoiceNumber: getValues('billOrInvoiceNumber'),
          requiresApproval: getValues('requiresApproval') === 'true' ? true : false,
          description: getValues('description'),
          dueDate: getValues('dueDate') ? new Date(getValues('dueDate')).toISOString() : undefined,
          recurringStartDate: getValues('recurringStartDate')
            ? new Date(getValues('recurringStartDate')).toISOString()
            : undefined,
          recurringEndDate: getValues('recurringEndDate')
            ? new Date(getValues('recurringEndDate')).toISOString()
            : undefined,
          shouldExpireRecurring: expires ? 'no' : 'yes',
          fileUrl: file
        };

        const filteredInvoiceData = Object.fromEntries(
          Object.entries(invoiceData).filter(([_, v]) => v != null && v !== '')
        );

        if (!isValid) {
          return;
        } else {
          if (statusType === 'sent') {
            handleSendInvoice.mutate(filteredInvoiceData);
          } else {
            handleSaveInvoice.mutate(filteredInvoiceData);
          }
        }
        return;
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  });

  const handleSubmitDraftBill = (status) => {
    const findCustomer = customers?.data?.data?.items?.find(
      (item) =>
        item?.companyName?.toLocaleLowerCase() === customerName?.toLocaleLowerCase() ||
        item?.name?.toLocaleLowerCase() === customerName?.toLocaleLowerCase()
    );

    if (!findCustomer && isValid) {
      handleCreateCustomer.mutate({
        companyName: customerName,
        name: customerName,
        type: 'business',
        phoneNumber: getValues("phoneNumber")
      });
    } else {
      setCustomerId(findCustomer?.id);

      const invoiceData = {
        customerId: findCustomer?.id,
        amount: `${getValues('amount')}`,
        frequency: frequency?.value,
        email: getValues('email'),
        status: 'draft',
        billOrInvoiceNumber: getValues('billOrInvoiceNumber'),
        requiresApproval: getValues('requiresApproval') === 'true' ? true : false,
        description: getValues('description'),
        dueDate: getValues('dueDate') ? new Date(getValues('dueDate')).toISOString() : undefined,
        recurringStartDate: getValues('recurringStartDate')
          ? new Date(getValues('recurringStartDate')).toISOString()
          : undefined,
        recurringEndDate: getValues('recurringEndDate')
          ? new Date(getValues('recurringEndDate')).toISOString()
          : undefined,
        shouldExpireRecurring: expires ? 'no' : 'yes',
        fileUrl: file
      };

      const filteredInvoiceData = Object.fromEntries(
        Object.entries(invoiceData).filter(([_, v]) => v != null && v !== '')
      );

      if (!isValid) {
        return;
      } else {
        handleSaveInvoice.mutate(filteredInvoiceData);
      }
    }
  };

  const handleSubmitBill = (status) => {
    const findCustomer = customers?.data?.data?.items?.find(
      (item) =>
        item?.companyName?.toLocaleLowerCase() === customerName?.toLocaleLowerCase() ||
        item?.name?.toLocaleLowerCase() === customerName?.toLocaleLowerCase()
    );

    if (!findCustomer) {
      handleCreateCustomer.mutate({
        companyName: customerName,
        name: customerName,
        type: 'business'
      });
    } else {
      setCustomerId(findCustomer?.id);

      const invoiceData = {
        customerId: findCustomer?.id,
        amount: `${getValues('amount')}`,
        frequency: frequency?.value,
        email: getValues('email'),
        status: 'draft',
        billOrInvoiceNumber: getValues('billOrInvoiceNumber'),
        requiresApproval: getValues('requiresApproval') === 'true' ? true : false,
        description: getValues('description'),
        dueDate: getValues('dueDate') ? new Date(getValues('dueDate')).toISOString() : undefined,
        recurringStartDate: getValues('recurringStartDate')
          ? new Date(getValues('recurringStartDate')).toISOString()
          : undefined,
        recurringEndDate: getValues('recurringEndDate')
          ? new Date(getValues('recurringEndDate')).toISOString()
          : undefined,
        shouldExpireRecurring: expires ? 'no' : 'yes',
        fileUrl: file
      };

      const filteredInvoiceData = Object.fromEntries(
        Object.entries(invoiceData).filter(([_, v]) => v != null && v !== '')
      );

      if (!isValid) {
        return;
      } else {
        handleSendInvoice.mutate(filteredInvoiceData);
      }
    }
  };

  return (
    <ExtractView>
      <div className="back-view">
        <button
          type="button"
          className="back"
          onClick={() => {
            navigate(-1);
          }}>
          <BackIcon />
        </button>
      </div>

      {!extractedFile ? (
        <DropArea
          setExtractedFile={setExtractedFile}
          setShowUploadButton={setShowUploadButton}
          setFile={setFile}
          setCustomer={setCustomer}
          setCustomerName={setCustomerName}
          setExtractedCustomerName={setExtractedCustomerName}
        />
      ) : null}

      <ContainerView>
        {extractedFile ? (
          <div className="wrapper">
            <FileView>
              {extractedFile?.documentUrl.includes('.pdf') ? (
                <PDFViewer file={extractedFile?.documentUrl} />
              ) : (
                <div className="image-viewer">
                  <img src={extractedFile?.documentUrl} alt="invoice" />
                </div>
              )}
            </FileView>
            <FormView>
              <form>
                {/* Vendor */}
                <div className="input-view">
                  <label htmlFor="vendor">Select customer</label>
                  <SearchDropdown2
                    options={customers?.data?.data?.items}
                    id="id"
                    name="companyName"
                    setVendorId={setCustomerId}
                    setVendorName={setCustomerName}
                    vendorName={customerName}
                    setVendorEmail={setCustomerEmail}
                  />
                </div>

                <div className="add-view">
                  <button type="button" onClick={() => setShowFindCustomerModal(true)}>
                    <PlusIcon /> Add new customer
                  </button>
                </div>

                {/* Email */}
                <div className="input-view">
                  <label htmlFor="name">Customer email</label>

                  <Input
                    type="email"
                    {...register('email')}
                    error={errors?.email?.message}
                    placeholder="Enter customer email"
                  />

                  {errors?.email && <span className="error-label">{errors?.email?.message}</span>}
                </div>

                {/* Phone */}
                <div className="input-view">
                  <label htmlFor="name">Customer phone number</label>

                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <PhoneNumberInput
                        name={name}
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        error={errors?.phoneNumber?.message ? true : false}
                        className={`currency-input ${errors?.phoneNumber && dirtyFields?.phoneNumber
                          ? ' has-error'
                          : dirtyFields?.phoneNumber
                            ? 'valid'
                            : ''
                          }`}
                      />
                    )}
                  />

                  {errors?.phoneNumber && (
                    <span className="error-label">{errors?.phoneNumber?.message}</span>
                  )}
                </div>

                {/* Bill amount */}
                <div className="input-view">
                  <label htmlFor="name">Invoice amount</label>
                  <Controller
                    name="amount"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <CurrencyyInput
                        name={name}
                        placeholder="Enter bill amount"
                        decimalsLimit={2}
                        allowNegativeValue={false}
                        prefix="₦"
                        onValueChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        error={!!errors?.amount?.message}
                        className={`currency-input ${errors?.amount && dirtyFields?.amount
                          ? ' has-error'
                          : dirtyFields?.amount
                            ? 'valid'
                            : ''
                          }`}
                      />
                    )}
                  />

                  {errors?.amount && <span className="error-label">{errors?.amount?.message}</span>}
                </div>

                {/* Bill frequency */}

                <div className="input-view">
                  <label htmlFor="vendor">Bill Frequency</label>
                  <SearchDropdown
                    options={billFrequency}
                    id="id"
                    name="name"
                    setValue={setFrequency}
                    value={frequency}
                    placeholder="Select frequency"
                  />
                </div>

                {frequency?.value !== 'one-time' && (
                  <>
                    {/* Frequency date */}
                    <div className="date-row">
                      <div className="input-view">
                        <label htmlFor="name">Start date</label>

                        <Input
                          type="date"
                          {...register('recurringStartDate')}
                          error={errors?.recurringStartDate?.message}
                        />

                        {errors?.recurringStartDate && (
                          <span className="error-label">{errors?.recurringStartDate?.message}</span>
                        )}
                      </div>
                      <div className="input-view">
                        <label htmlFor="name">End date</label>

                        <Input
                          type="date"
                          {...register('recurringEndDate')}
                          error={errors?.recurringEndDate?.message}
                          disabled={!!expires}
                          className={expires ? 'disabled' : ''}
                        />

                        {errors?.recurringEndDate && (
                          <span className="error-label">{errors?.recurringEndDate?.message}</span>
                        )}
                      </div>
                    </div>

                    {/* Checkbox */}
                    <div className="input-view checkbox-view">
                      <input
                        type="checkbox"
                        name="expires"
                        id="expires"
                        value="false"
                        className="checkbox"
                        checked={expires}
                        onChange={() => setExpires(!expires)}
                      />
                      <label htmlFor="expires">Never expires</label>
                    </div>
                  </>
                )}

                {/* Bill number */}
                <div className="input-view">
                  <label htmlFor="billOrInvoiceNumber">Invoice no.</label>
                  <Input
                    type="text"
                    placeholder="Enter invoice number"
                    {...register('billOrInvoiceNumber')}
                    error={!!errors?.billOrInvoiceNumber?.message}
                  />

                  {errors?.billOrInvoiceNumber && (
                    <span className="error-label">{errors?.billOrInvoiceNumber?.message}</span>
                  )}
                </div>

                {/* Due date */}
                <div className="input-view">
                  <label htmlFor="name">Due date</label>

                  <Input type="date" {...register('dueDate')} error={!!errors?.dueDate?.message} />

                  {errors?.dueDate && (
                    <span className="error-label">{errors?.dueDate?.message}</span>
                  )}
                </div>

                {/* Description */}
                <div className="input-view">
                  <label htmlFor="description">Description</label>
                  <TextArea
                    type="number"
                    {...register('description')}
                    error={!!errors?.description?.message}
                    placeholder="Enter description"
                  />

                  {errors?.description && (
                    <span className="error-label">{errors?.description?.message}</span>
                  )}
                </div>

                {/* Document */}
                {/* <FilePicker file={file} setFile={setFile} acceptsPDF /> */}

                {/* Approval */}
                <div className="input-view">
                  <label htmlFor="vendor">Will the invoice require approval?</label>
                  <div className="options">
                    <div className="option">
                      <input
                        type="radio"
                        name="approval"
                        id="approval"
                        value={true}
                        {...register('requiresApproval')}
                      // onChange={() => setApproval(true)}
                      />
                      <span>Yes</span>
                    </div>

                    <div className="option">
                      <input
                        type="radio"
                        name="approval"
                        id="approval"
                        value={false}
                        {...register('requiresApproval')}
                      // onChange={() => setApproval(false)}
                      />
                      <span>No</span>
                    </div>
                  </div>
                  {errors?.requiresApproval && (
                    <span className="error-label">{errors?.requiresApproval?.message}</span>
                  )}
                </div>

                <div className="cta-view">
                  <button
                    className="cta-view__save"
                    type="button"
                    onClick={() => {
                      setStatusType('sent');
                      handleSubmit(handleSubmitBill)();
                    }}>
                    {handleSendInvoice.isLoading ||
                      (handleCreateCustomer?.isLoading && statusType === 'sent') ? (
                      <Oval
                        color="#FFF"
                        secondaryColor="#ddd"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    ) : (
                      'Continue'
                    )}
                  </button>
                  <button
                    className="cta-view__draft"
                    type="button"
                    onClick={() => {
                      setStatusType('draft');
                      handleSubmit(handleSubmitDraftBill)();
                    }}>
                    {handleSaveInvoice.isLoading ||
                      (handleCreateCustomer?.isLoading && statusType !== 'sent') ? (
                      <Oval
                        color="#92C22C"
                        secondaryColor="#ddd"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    ) : (
                      'Save as draft'
                    )}
                  </button>
                </div>
              </form>
            </FormView>
          </div>
        ) : null}
      </ContainerView>

      <NewCustomerModal
        showModal={showCustomerForm}
        setShowModal={setShowCustomerForm}
        setCustomer={setCustomer}
        setCustomerName={setCustomerName}
      />

      <FindCustomerModal
        showModal={showFindCustomerModal}
        setShowModal={setShowFindCustomerModal}
        showAddNewCustomer={showCustomerForm}
        setShowAddNewCustomer={setShowCustomerForm}
        setCustomer={setCustomer}
        setCustomerName={setCustomerName}
      />
    </ExtractView>
  );
}

export default withAuthentication(InvoiceExtract);

const ExtractView = styled.div`
  width: 1300px;
  margin: 0 auto;
  margin-top: 50px;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
    padding: 0 16px;
  }

  .back-view {
  }

  .upload {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 200px;
      height: 64px;
      background: ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
      border: none;
      border-radius: 16px;
      font-weight: 600;
    }
  }

  .loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;

    p {
      font-size: 1rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.activeColor};
      width: 280px;
      text-align: center;
      margin-top: 20px;
    }
  }
`;

const ContainerView = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }

  .wrapper {
    width: 85%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;

    @media ${device.phone} {
      width: 100%;
      flex-direction: column;
    }
  }
`;

const FileView = styled.div`
  width: 48%;
  max-height: 80vh;
  padding: 10px;
  border: 1.7131px dashed #cccccc;
  overflow-y: scroll;

  @media ${device.phone} {
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
  }

  .image-viewer {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const FormView = styled.div`
  width: 48%;
  height: 100%;
  display: flex;
  align-items: flex-start;

  @media ${device.phone} {
    width: 100%;
  }

  form {
    width: 100%;
    max-width: 460px;

    .input-view {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      input {
        width: 100%;
      }

      label {
        font-size: 0.875rem;
        font-weight: 400;

        span {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 8px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }

      .options {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 14px;

        .option {
          width: 40%;
          display: flex;
          align-items: center;

          input {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-right: 10px;
            accent-color: ${({ theme }) => theme.colors?.secondary};
            background-color: ${({ theme }) => theme.colors?.primaryFocus};
            cursor: pointer;
            color: ${({ theme }) => theme.colors?.primary};
          }

          span {
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: 0px;
          }
        }
      }
    }

    .checkbox-view {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 30px;
      margin-top: 10px;
      flex-wrap: nowrap;

      .checkbox {
        margin-right: 5px;
        accent-color: ${({ theme }) => theme.colors?.secondary};
        cursor: pointer;
        width: 16px;
        height: 16px;
      }

      label {
        cursor: pointer;
      }
    }

    .date-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .input-view {
        width: 48%;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        input {
          width: 100%;
        }

        .disabled {
          background-color: ${({ theme }) => theme.colors?.layer2};

          &::placeholder {
            color: ${({ theme }) => theme.colors?.info};
          }
        }
      }
    }

    .vendor {
      margin-bottom: 10px;
    }

    .add-view {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      button {
        color: ${({ theme }) => theme.colors?.secondary};
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-right: 10px;
        }
      }
    }

    .select-view {
      width: 100%;
      margin-bottom: 20px;

      label {
        font-size: 0.875rem;
        font-weight: 400;

        span {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }

      .options {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 14px;

        .option {
          width: 33.333%;
          display: flex;
          align-items: center;
          cursor: pointer;

          .radio-button {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colors?.primaryFocus};
            margin-right: 10px;

            .dot {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: ${({ theme }) => theme.colors?.primary};
            }
          }

          p {
            font-size: 0.875rem;
            font-weight: 400;
            color: ${({ theme }) => theme.colors?.secondary};
          }
        }
      }
    }

    .cta-view {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      margin-bottom: 50px;

      button {
        width: 49%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 16px;
      }

      .cta-view__save {
        background-color: ${({ theme }) => theme.colors?.primary};
        color: ${({ theme }) => theme.colors?.white};
        margin-bottom: 20px;
        width: 49%;
      }

      .cta-view__draft {
        background-color: ${({ theme }) => theme.colors?.white};
        color: ${({ theme }) => theme.colors?.primary};
        border: 1px solid ${({ theme }) => theme.colors?.primary};
        width: 49%;
      }
    }
  }
`;
