import React from 'react';
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';
import { Modal2 } from './Modal2';
import { ReactSVG } from 'react-svg';
import { icons } from '@/assets/icons/icons';
import { Link } from 'react-router-dom';

export default function AnnouncementModal({
  openAnnouncementModal,
  setOpenAnnouncementModal,
  notification
}) {
  // Get the link from notification
  const notificationLink = notification?.link || '';

  // Check if it's an external link (starts with http:// or https:// or www.)
  const isExternalLink = /^(https?:\/\/|www\.)/.test(notificationLink);

  const linkElement = notificationLink ? (
    isExternalLink ? (
      <a href={notificationLink} target="_blank" rel="noopener noreferrer">
        Learn more
      </a>
    ) : (
      <Link to={notificationLink}>Learn more</Link>
    )
  ) : null;

  const closeModal = () => {
    localStorage.setItem('closedAnnouncementModal', JSON.stringify(notification?.id));
    setOpenAnnouncementModal(false);
  };

  return (
    <Modal2 isOpen={openAnnouncementModal}>
      <ContentView>
        <button className="close-btn" onClick={closeModal}>
          <ReactSVG src={icons.closeIcon} />
        </button>
        <div className="illustration">
          <img src={notification?.image || ''} alt="Notification" />
        </div>
        <Content>
          <p>{notification?.message}</p>
          {linkElement}
        </Content>
      </ContentView>
    </Modal2>
  );
}

const ContentView = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;

  .illustration {
    width: 100%;
    height: 250px;
    border-radius: 15px 15px 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px 15px 0 0;
      aspect-ratio: auto 16/9;
      image-rendering: pixelated;
    }
  }

  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1000;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  @media ${device.phone} {
    padding: 0 1rem;
  }

  p {
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.secondary};
    text-align: left;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }

  a {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
    text-align: left;
    text-decoration: none;
    margin-top: 10px;
    text-decoration: underline;
  }
`;

export const LoaderView = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
