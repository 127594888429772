import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@/components/Button/Button';
import { fetchCompliance, fetchComplianceState } from '@/services/complianceApi';
import { device } from '@/constants/breakpoints';
import { icons } from '@/assets/icons/icons';
import { ReactSVG } from 'react-svg';
import ComplianceModal from './ComplianceModal';
import { Oval } from 'react-loader-spinner';
import { useSearchParams } from 'react-router-dom';

function Compliance() {
  const [openComplianceModal, setOpenComplianceModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const compliance = useQuery({
    queryKey: ['compliance'],
    queryFn: fetchCompliance
  });

  const complianceState = useQuery({
    queryKey: ['complianceState'],
    queryFn: fetchComplianceState
  });

  const getButtonText = () => {
    const states = complianceState?.data?.data;
    if (!states) return 'Start verification';

    const allTrue = Object.values(states).every((item) => item.bool === true);
    const hasTrue = Object.values(states).some((item) => item.bool === true);

    if (allTrue) return 'Update compliance';
    if (hasTrue) return 'Continue verification';
    return 'Start verification';
  };

  if (compliance.isLoading || complianceState.isLoading) {
    return (
      <LoaderView>
        <div className="loader">
          <Oval
            color="#92C22C"
            secondaryColor="#ddd"
            height={50}
            width={50}
            strokeWidth={4}
            ariaLabel="loading"
          />
        </div>
      </LoaderView>
    );
  }

  return (
    <>
      <ComplianceView>
        <TitleView>
          <div className="complaince-status">
            <h3>Compliance</h3>
            <div
              className={`status-view ${compliance?.data?.data?.isVerified ? 'verified' : 'pending'}`}>
              {<p>{compliance?.data?.data?.isVerified ? 'Verified' : 'Pending'}</p>}{' '}
              {compliance?.data?.data?.isVerified && <ReactSVG src={icons.passedCheck} />}
            </div>
          </div>
          <p>
            Complete your compliance in just 3 steps by providing the necessary information below.
          </p>
        </TitleView>
        <ComplianceCard>
          <div className="name-view">
            <ReactSVG src={icons.profileIcon2} />
            <h3>Personal information</h3>
          </div>
          <div className={`status-view ${complianceState?.data?.data?.personalInfo?.status}`}>
            <p>{complianceState?.data?.data?.personalInfo?.status}</p>
          </div>
        </ComplianceCard>
        <ComplianceCard>
          <div className="name-view">
            <ReactSVG src={icons.docsIcon} />
            <h3>Business documentation</h3>
          </div>
          <div
            className={`status-view ${complianceState?.data?.data?.businessDocumentation?.status}`}>
            <p>{complianceState?.data?.data?.businessDocumentation?.status}</p>
          </div>
        </ComplianceCard>
        <ComplianceCard>
          <div className="name-view">
            <ReactSVG src={icons.selfieIcon} />
            <h3>Selfie verification</h3>
          </div>
          <div className={`status-view ${complianceState?.data?.data?.selfieVerification?.status}`}>
            <p>{complianceState?.data?.data?.selfieVerification?.status}</p>
          </div>
        </ComplianceCard>

        <div className="button-view">
          <Button
            type="button"
            styleType="primary"
            width="100%"
            onClick={() => {
              setSearchParams(`tab=compliance&step=personalInfo`);
              setOpenComplianceModal(true);
            }}>
            {getButtonText()}
          </Button>
        </div>
      </ComplianceView>
      <ComplianceModal
        openComplianceModal={openComplianceModal}
        setOpenComplianceModal={setOpenComplianceModal}
        compliance={compliance?.data?.data}
      />
    </>
  );
}

export default Compliance;

const ComplianceView = styled.div`
  width: 100%;
  padding: 0 60px;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
    padding: 0 0px;
  }

  .button-view {
    width: 230px;
    margin-top: 50px;

    @media ${device.phone} {
      width: 100%;
    }
  }

  form {
    width: 75%;

    @media ${device.phone} {
      width: 100%;
      padding: 0;
    }

    .input-view {
      width: 100%;
      margin-bottom: 26px;

      .label-view {
        width: 100%;
        display: flex;
        margin-bottom: 8px;

        label {
          font-size: 1rem;
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 8px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }
    }

    .button-view {
      width: 230px;
      margin-top: 50px;

      @media ${device.phone} {
        width: 100%;
      }
    }
  }
`;

const TitleView = styled.div`
  width: 100%;
  margin-bottom: 30px;

  .complaince-status {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }

    .status-view {
      width: fit-content;
      display: flex;
      align-items: center;
      margin-left: 10px;
      border-radius: 20px;

      svg {
        width: 15px;
        height: 15px;
        margin-left: 5px;
      }
    }

    .verified {
      padding: 5px 10px;
      border-radius: 20px;
      background-color: ${({ theme }) => theme.colors?.white};
      border: 1px solid ${({ theme }) => theme.colors?.success};

      p {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.success};
      }
    }

    .pending {
      padding: 5px 10px;
      border-radius: 20px;
      background-color: ${({ theme }) => theme.colors?.white};
      border: 1px solid ${({ theme }) => theme.colors?.warning};

      p {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.warning};
      }
    }
  }

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.activeColor};
    line-height: 22px;
    max-width: 90%;

    @media ${device.phone} {
      max-width: 100%;
    }

    a {
      color: ${({ theme }) => theme.colors?.primary};
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const ComplianceCard = styled.div`
  width: 60%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors?.inputBackground};
  margin-bottom: 20px;

  .name-view {
    display: flex;
    align-items: center;

    svg {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }

    h3 {
      font-size: 1rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
    }
  }

  .status-view {
    padding: 5px 12px;
    border-radius: 20px;

    p {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.success};
    }
  }

  .Approved {
    background-color: ${({ theme }) => theme.colors?.white};
    color: ${({ theme }) => theme.colors?.success};
    border: 1px solid ${({ theme }) => theme.colors?.success};
  }

  .Pending,
  .Submitted {
    background-color: ${({ theme }) => theme.colors?.white};
    color: ${({ theme }) => theme.colors?.warning};
    border: 1px solid ${({ theme }) => theme.colors?.warning};

    p {
      color: ${({ theme }) => theme.colors?.warning};
    }
  }
`;

const LoaderView = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
