import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';

export function isValidEmail(email = '') {
  const validator = yup.string().email('invalid email').required('Email is required');

  try {
    validator.validateSync(email);

    return true;
  } catch (error) {
    return false;
  }
}

export function isValidPhone(phone = '') {
  const validator = yup
    .string()
    .required("Vendor's phone number is required")
    .min(11, 'Enter a valid phone number')
    .test('validPhoneNumber', 'Please enter a valid phone number', (value) => {
      if (!value) return true; // Let required validation handle empty case
      try {
        const phoneNumber = parsePhoneNumber(value);
        return phoneNumber.isValid();
      } catch {
        return false;
      }
    })

  try {
    validator.validateSync(phone);

    return true;
  } catch (error) {
    return false;
  }
}

export function isValidDueDate(dueDate = '') {
  const validator = yup.string().min(8, 'Invalid date entered').required('Due date is required');

  try {
    validator.validateSync(dueDate);

    return true;
  } catch (error) {
    return false;
  }
}

export function isValidAmount(amount = '') {
  const validator = yup
    .string()
    .min(3, 'Amount is missing or invalid')
    .required('Amount is required');

  try {
    validator.validateSync(amount);

    return true;
  } catch (error) {
    return false;
  }
}

export function isValidCompanyName(customerName = '') {
  const validator = yup
    .string()
    .min(3, 'Customer name is missing')
    .required('Customer name is required');

  try {
    validator.validateSync(customerName);

    return true;
  } catch (error) {
    return false;
  }
}

export function isValidDescription(description = '') {
  const validator = yup.string();

  try {
    validator.validateSync(description);

    return true;
  } catch (error) {
    return false;
  }
}

export function isValidInvoiceNumber(invoiceNumber = '') {
  const validator = yup.string().required('Invoice number is required');

  try {
    validator.validateSync(invoiceNumber);

    return true;
  } catch (error) {
    return false;
  }
}

export function isValidApproval(requiresApproval = '') {
  const validator = yup.string().required('Requires approval is required');

  try {
    validator.validateSync(requiresApproval);

    return true;
  } catch (error) {
    return false;
  }
}
