import { Modal } from '@/components/Modal';
import React from 'react';
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';
import { ReactSVG } from 'react-svg';
import { icons } from '@/assets/icons/icons';

export default function ComplianceSuccessModal({
  showSuccessModal,
  setShowSuccessModal,
  closeComplianceModal
}) {

  return (
    <Modal
      isOpen={showSuccessModal}
      onClose={() => {
        setShowSuccessModal(false);
        closeComplianceModal(false);
      }}
      title="">
      <ContentView>
        <Content>
          <div className="icon-view">
            <ReactSVG src={icons.passedSelfie} />
          </div>

          <div className="passed">
            <ReactSVG src={icons.passedCheck} />
            Success!
          </div>

          <p>Selfie verification has been submitted successfully, pending approval.</p>
        </Content>
      </ContentView>
    </Modal>
  );
}

const ContentView = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon-view {
    width: 100px;
    height: 100px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media ${device.phone} {
    padding: 0 1rem;
  }

  .passed {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 190px;
    height: 56px;
    background-color: #d4fff3;
    color: #00ba88;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    margin-top: 40px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors?.secondary};
    margin-top: 20px;
    text-align: center;
  }
`;

export const LoaderView = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
