import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { fetchCompliance, submitBusinessInfo, submitCompliance } from '@/services/complianceApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import Input from '@/components/Input/Input';
import { device } from '@/constants/breakpoints';
import SearchDropdown from '@/components/SearchDropdown';
import FilePicker2 from '@/components/FilePicker2';
import { businessRegTypes, businessRegYears } from '@/utils/data.utils';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

const schema = yup
  .object({
    websiteUrl: yup
      .string()
      .nullable()
      .notRequired()
      .test(
        'is-url-correct',
        'Enter correct url!',
        (value) =>
          !value ||
          /((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
            value
          )
      ),
    registrationNumber: yup
      .string()
      .matches(/^[a-zA-Z0-9_-]*$/, 'Only letters, numbers, hyphens and underscores are allowed')
      .required('Registration number is required'),
    tin: yup
      .string()
      .matches(/^[a-zA-Z0-9_-]*$/, 'Only letters, numbers, hyphens and underscores are allowed')
      .required('TIN is required'),
    aboutBusiness: yup
      .string()
      .matches(
        /^[a-zA-Z0-9_\-,.\s]*$/,
        'Special characters are not allowed except for hyphens, commas and periods'
      )
      .required('Business description is required')
  })
  .required();

const BusinessInfoStep = ({ onPrevious, onNext, complianceState }) => {
  const queryClient = useQueryClient();

  const [businessType, setBusinessType] = useState({});
  const [businessYear, setBusinessYear] = useState({});
  const [certificateDoc, setCertificateDoc] = useState(null);
  const [memorandumDoc, setMemorandumDoc] = useState(null);
  const [proofOfBusinessDoc, setProofOfBusinessDoc] = useState(null);

  const compliance = useQuery({
    queryKey: ['compliance'],
    queryFn: fetchCompliance,
    onSuccess: (data) => {
      const findBusinessType = businessRegTypes.find(
        (item) => item.title === data?.data?.businessType
      );
      const findBusinessYear = businessRegYears.find(
        (item) => item.title === data?.data?.businessYear
      );
      setBusinessType(findBusinessType);
      setBusinessYear(findBusinessYear);
      setCertificateDoc(data?.data?.certificateUrl);
      setMemorandumDoc(data?.data?.memorandumUrl);
      setProofOfBusinessDoc(data?.data?.proofOfBusinessUrl);
    }
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      websiteUrl: compliance?.data?.data?.websiteUrl?.trim() || '',
      registrationNumber: compliance?.data?.data?.registrationNumber?.trim(),
      tin: compliance?.data?.data?.tin?.trim(),
      aboutBusiness: compliance?.data?.data?.aboutBusiness?.trim()
    }
  });

  useEffect(() => {
    reset({
      websiteUrl: compliance?.data?.data?.websiteUrl || '',
      registrationNumber: compliance?.data?.data?.registrationNumber,
      tin: compliance?.data?.data?.tin,
      aboutBusiness: compliance?.data?.data?.aboutBusiness
    });
  }, [compliance?.data?.data, reset]);

  const handleSubmitBusinessInfo = useMutation(submitBusinessInfo, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.every((key) =>
            ['compliance', 'complianceState', 'user', 'dashboard-user'].includes(key)
          )
      });
      onNext();

      toast.success('Business information submitted successfully');
    },
    onError: (error) => {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message[0]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  });

  const onSubmit = (data) => {
    const formData = {
      businessType: businessType?.title,
      businessYear: businessYear?.title,
      tin: data?.tin,
      registrationNumber: data?.registrationNumber,
      aboutBusiness: data?.aboutBusiness,
      websiteUrl: data?.websiteUrl || null,
      certificateUrl: certificateDoc,
      memorandumUrl: memorandumDoc,
      proofOfBusinessUrl: proofOfBusinessDoc
    };
    if (!formData.certificateUrl) {
      toast.error('Please upload Certificate of Incorporation');
      return;
    }
    if (!formData.memorandumUrl) {
      toast.error('Please upload Memorandum and Articles of Association');
      return;
    }
    if (!formData.proofOfBusinessUrl) {
      toast.error('Please upload Proof of Business Address');
      return;
    }
    if (formData.websiteUrl && !/^https?:\/\//i.test(formData.websiteUrl)) {
      formData.websiteUrl = 'https://' + formData.websiteUrl;
    }
    if (!businessType?.value) {
      toast.error('Please select business registration type');
      return;
    }
    if (!businessYear?.value) {
      toast.error('Please select business registration year');
      return;
    }
    if (
      complianceState?.businessDocumentation?.status === 'Approved' ||
      complianceState?.businessDocumentation?.status === 'Submitted'
    ) {
      const aboutBusinessChanged = data?.aboutBusiness !== compliance?.data?.data?.aboutBusiness;
      const websiteUrlChanged = data?.websiteUrl !== compliance?.data?.data?.websiteUrl;

      if (aboutBusinessChanged || websiteUrlChanged) {
        const formData = {
          aboutBusiness: data?.aboutBusiness,
          websiteUrl: data?.websiteUrl || null,
        };

        handleSubmitBusinessInfo.mutate(formData);
      } else {
        onNext();
      }

    } else {
      handleSubmitBusinessInfo.mutate(formData);
    }
  };

  return (
    <Styles>
      {compliance.isFetching ? (
        <LoaderView>
          <div className="loader">
            <Oval
              color="#92C22C"
              secondaryColor="#ddd"
              height={50}
              width={50}
              strokeWidth={4}
              ariaLabel="loading"
            />
          </div>
        </LoaderView>
      ) : (
        <>
          <form>
            <div className="input-view">
              <div className="label-view">
                <label>Business registration type</label>
              </div>
              <SearchDropdown
                options={businessRegTypes}
                id="id"
                name="title"
                setValue={setBusinessType}
                value={businessType}
                placeholder="Select business registration type"
                disabled={
                  complianceState?.businessDocumentation?.status === 'Approved' ||
                  complianceState?.businessDocumentation?.status === 'Submitted'
                }
              />
            </div>
            <div className="input-view">
              <div className="label-view">
                <label>Business registration year</label>
              </div>
              <SearchDropdown
                options={businessRegYears}
                id="id"
                name="title"
                setValue={setBusinessYear}
                value={businessYear}
                placeholder="Select business registration year"
                disabled={
                  complianceState?.businessDocumentation?.status === 'Approved' ||
                  complianceState?.businessDocumentation?.status === 'Submitted'
                }
              />
            </div>
            <div className="input-view">
              <div className="label-view">
                <label>Certificate of Incorporation</label>
              </div>
              <FilePicker2
                file={certificateDoc}
                setFile={setCertificateDoc}
                acceptsPDF
                disabled={
                  complianceState?.businessDocumentation?.status === 'Approved' ||
                  complianceState?.businessDocumentation?.status === 'Submitted'
                }
              />
            </div>
            <div className="input-view">
              <div className="label-view">
                <label>Memorandum and Articles of Association/Status Report</label>
              </div>
              <FilePicker2
                file={memorandumDoc}
                setFile={setMemorandumDoc}
                acceptsPDF
                disabled={
                  complianceState?.businessDocumentation?.status === 'Approved' ||
                  complianceState?.businessDocumentation?.status === 'Submitted'
                }
              />
            </div>
            <div className="input-view">
              <div className="label-view">
                <label htmlFor="registrationNumber">RC Number</label>
              </div>
              <Input
                type="text"
                placeholder="Enter RC Number"
                {...register('registrationNumber')}
                error={errors?.registrationNumber?.message}
                disabled={
                  complianceState?.businessDocumentation?.status === 'Approved' ||
                  complianceState?.businessDocumentation?.status === 'Submitted'
                }
              />
              {errors?.registrationNumber && (
                <span className="error-label">{errors?.registrationNumber?.message}</span>
              )}
            </div>
            <div className="input-view">
              <div className="label-view">
                <label htmlFor="tin">Tax Identification Number</label>
              </div>
              <Input
                type="text"
                placeholder="Enter Tax Identification Number"
                {...register('tin')}
                error={errors?.tin?.message}
                disabled={
                  complianceState?.businessDocumentation?.status === 'Approved' ||
                  complianceState?.businessDocumentation?.status === 'Submitted'
                }
              />
              {errors?.tin && <span className="error-label">{errors?.tin?.message}</span>}
            </div>
            <div className="input-view">
              <div className="label-view">
                <label>Proof of Business Address (Utility bill)</label>
              </div>
              <FilePicker2
                file={proofOfBusinessDoc}
                setFile={setProofOfBusinessDoc}
                acceptsPDF
                disabled={
                  complianceState?.businessDocumentation?.status === 'Approved' ||
                  complianceState?.businessDocumentation?.status === 'Submitted'
                }
              />
            </div>
            <div className="input-view">
              <div className="label-view">
                <label htmlFor="aboutBusiness">Business description</label>
              </div>
              <Input
                type="text"
                placeholder="Enter business description"
                {...register('aboutBusiness')}
                error={errors?.aboutBusiness?.message}
              />
              {errors?.aboutBusiness && (
                <span className="error-label">{errors?.aboutBusiness?.message}</span>
              )}
            </div>
            <div className="input-view">
              <div className="label-view">
                <label htmlFor="websiteUrl">Website (Optional)</label>
              </div>
              <Input
                type="text"
                placeholder="Enter website url"
                {...register('websiteUrl')}
                error={errors?.websiteUrl?.message}
              />
              {errors?.websiteUrl && (
                <span className="error-label">{errors?.websiteUrl?.message}</span>
              )}
            </div>
          </form>
          <div className="actions">
            <button className="previous" onClick={onPrevious}>
              Back
            </button>
            <button className="next" onClick={handleSubmit(onSubmit)}>
              {handleSubmitBusinessInfo.isLoading ? (
                <Oval
                  color="#fff"
                  secondaryColor="#ddd"
                  height={20}
                  width={20}
                  strokeWidth={2}
                  ariaLabel="loading"
                />
              ) : (
                'Next'
              )}
            </button>
          </div>
        </>
      )}
    </Styles>
  );
};

export default BusinessInfoStep;

const Styles = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;

  form {
    width: 100%;
    height: 400px;
    padding: 0 1rem;
    padding-bottom: 60px;
    overflow-y: scroll;

    @media ${device.phone} {
      width: 100%;
      padding: 0;
    }

    .input-view {
      width: 100%;
      margin-bottom: 26px;

      .label-view {
        width: 100%;
        display: flex;
        margin-bottom: 8px;

        label {
          font-size: 1rem;
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 8px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }
    }

    .button-view {
      width: 230px;
      margin-top: 50px;

      @media ${device.phone} {
        width: 100%;
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors?.white};
    padding: 1rem;
    box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 15px 15px;

    button {
      margin-left: 20px;
      height: 40px;
      width: 120px;
      border-radius: 10px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .next {
      background-color: ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
    }

    .previous {
      border: 1px solid ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.primary};
    }
  }
`;

const LoaderView = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
